import { Checkbox, DefaultButton, Dialog, PrimaryButton } from "office-ui-fabric-react";
import * as React from "react";

import { IVertical, IVerticalBuild } from "../containers/HomePage/reducer";
import { AppContainer } from "../utils/localStyledComponents";
import { VerticalToolsJenkins } from "../utils/VerticalTools";
import { VerticalApplication } from "./Vertical/VerticalApplication";
import VerticalHeader from "./VerticalHeader";

interface IVerticalContentProps {
    vertical: IVertical;
    verticalBuild?: IVerticalBuild;
}

interface IState {
    isDialogOpen: boolean;
    selectedApplications: string[];
}

export default class VerticalContent extends React.PureComponent<IVerticalContentProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            isDialogOpen: false,
            selectedApplications: [],
        };
    }

    public render(): JSX.Element {
        // TODO: Remake the VerticalApplication component
        let appsContent: JSX.Element[] = [];
        if (this.props.vertical.applications) {
            appsContent = this.props.vertical.applications.map((app) => {
                const vertAppCommits = (app.commits || []).map((commit) => {
                    return {
                        ...commit,
                        cloud: this.props.vertical.cloud,
                        region: this.props.vertical.region,
                        vertical: this.props.vertical.vertical,
                    };
                });
                return (
                    <VerticalApplication
                        key={app.application}
                        application={app.application}
                        commits={vertAppCommits}
                        vertical={this.props.vertical.vertical}
                        cloud={this.props.vertical.cloud}
                        addApplicationCallback={this.showAddApps}
                    />
                );
            });
        }
        return (
            <div>
                <VerticalHeader
                    vertical={this.props.vertical}
                    verticalBuild={this.props.verticalBuild}
                />
                <AppContainer>
                    {appsContent}
                </AppContainer>
                <Dialog
                    dialogContentProps={{title: "Add applications"}}
                    hidden={!this.state.isDialogOpen}
                    onDismiss={this.hideAddApps}
                >
                    <div className="checkbox-dialog-group">
                        {this.props.vertical.applications.map((app) => {
                            return (<Checkbox key={app.application} checked={this.state.selectedApplications.some((s) => s === app.application)} onChange={this.onAddAppsCheckChange.bind(this, app.application)} label={app.application}/>);
                        })}
                    </div>
                    <div className="dialog-buttons">
                        <PrimaryButton onClick={this.addAppsConfirm}>Submit</PrimaryButton>
                        <DefaultButton primary={false} onClick={this.hideAddApps}>Cancel</DefaultButton>
                    </div>
                </Dialog>
            </div>
        );
    }

    private onAddAppsCheckChange = (application: string, ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        if (checked) {
            this.setState({
                ...this.state,
                selectedApplications: this.state.selectedApplications.concat([application]),
            });
        } else {
            this.setState({
                ...this.state,
                selectedApplications: this.state.selectedApplications.filter((a) => a !== application),
            });
        }
    }

    private showAddApps = (application: string) => {
        this.setState({
            ...this.state,
            isDialogOpen: true,
            selectedApplications: [application],
        });
    }

    private hideAddApps = () => {
        this.setState({
            ...this.state,
            isDialogOpen: false,
            selectedApplications: [],
        });
    }

    private addAppsConfirm = () => {
        window.open(VerticalToolsJenkins.JobStartFormUrl("add-applications", [
            ["Branch", this.props.vertical.vertical],
            ["Applications", this.state.selectedApplications.join(",")],
        ]));
        this.setState({
            ...this.state,
            isDialogOpen: false,
            selectedApplications: [],
        });
    }
}
