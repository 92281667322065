import { Icon, IconButton } from "office-ui-fabric-react";
import * as React from "react";

import { DateTime, Is, IReactEnumable } from "../../utils/Base";
import styled from "../../utils/localStyledComponents";
import { VerticalToolsUrl } from "../../utils/VerticalTools";
import { IPipelineRun } from "../../containers/HomePage/reducer";

const ApplicationCommitIcon = styled(Icon)`
    font-size: 0.75rem;
    padding: 0 .25rem;
`;

export interface IReplicaSetProps extends IReactEnumable {
    Commit: string;
    ReplicaSetName: string;
    ReplicaSetCreationTime: string;
    PodStatus: string;
    BuildVersion: string;
    ApplicationName: string;
    Namespace: string;
    StampName: string;
    StampSubscription: string;
    ClusterName: string;
    EndpointWeight: number;
    buddyPipelineRuns: IPipelineRun[];
    officialPipelineRuns: IPipelineRun[];
}

export class StackApplicationReplicaSet extends React.PureComponent<IReplicaSetProps> {
    public render() {
        const replicaSetRoute = VerticalToolsUrl.replicaSetRoute(
            this.props.StampSubscription, this.props.StampName, this.props.Namespace, this.props.ClusterName, this.props.ReplicaSetName, this.props.ApplicationName);
        const runs = ["main", "int", "upr-usj"].includes(this.props.StampName) ? this.props.officialPipelineRuns : this.props.buddyPipelineRuns;
        const run = runs.find(run => {
            const build_version = run.build_version.split('.').map(i => parseInt(i, 10).toString()).join('.')
            return build_version === this.props.BuildVersion
        })
        return (
            <div className="ui segment stamp-application-replicaset">
                <h4 className="ui header">
                    <div className="content">
                        {Is.Null(replicaSetRoute)
                            ? this.props.ReplicaSetName
                            : (<a href={replicaSetRoute} target="_blank" rel="noopener noreferrer">{this.props.ReplicaSetName}</a>)}
                    </div>
                </h4>

                <table>
                    <tbody>
                        <tr>
                            <th>ReplicaSet Created</th>
                            <td>{DateTime.ToShortDate(this.props.ReplicaSetCreationTime)}</td>
                        </tr>
                        <tr>
                            <th>Pod Status</th>
                            <td>{this.props.PodStatus}</td>
                        </tr>
                        {this.props.ApplicationName === "traefik" ? null : (
                            <>
                                <tr>
                                    <th>Pf-main Commit</th>
                                    <td>
                                        <a href={VerticalToolsUrl.githubCommit(this.props.Commit)} target="_blank" rel="noopener noreferrer">
                                            {this.props.Commit.substring(0, 7)}
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Stack Build</th>
                                    <td>
                                        {Is.Null(run)
                                            ? this.props.BuildVersion
                                            : (<a href={run.web_link} target="_blank" rel="noopener noreferrer">
                                                {this.props.BuildVersion}
                                            </a>)}
                                    </td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}
