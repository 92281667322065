import { Is } from "../../utils/Base";
import { VerticalToolsSort, VerticalToolsUrl } from "../../utils/VerticalTools";
import { TOGGLE_AUTO_REFRESH, TOGGLE_DARK_MODE } from "../Header/constants";
import {
    SET_PRODUCTION_ONLY_FILTER, SET_SEARCH_FILTER, SET_SELECTED_CLOUD_VERTICAL,
    TOGGLE_ALL_COLLAPSE, TOGGLE_COLLAPSE, SET_CLASSIC_VIEW, SET_SELECTED_STAMP_VERTICAL, SET_APPLICATION_VIEW,
} from "../PrimaryNav/constants";
import {
    LOAD_VERTICAL_BUILDING_STATUS_ERROR, LOAD_VERTICAL_BUILDING_STATUS_SUCCESS,
    LOAD_VERTICALS, LOAD_VERTICALS_ERROR, LOAD_VERTICALS_SUCCESS, LOAD_STAMPS, LOAD_STAMPS_ERROR, LOAD_STAMPS_SUCCESS,
    LOAD_BUDDYPIPELINERUNS, LOAD_BUDDYPIPELINERUNS_SUCCESS, LOAD_BUDDYPIPELINERUNS_ERROR,
    LOAD_OFFICIALPIPELINERUNS, LOAD_OFFICIALPIPELINERUNS_SUCCESS, LOAD_OFFICIALPIPELINERUNS_ERROR,
} from "./constants";

export interface ICloud {
    cloud: string;
    verticals: IVertical[];
}

export interface IVertical {
    cloud: string;
    region: string;
    vertical: string;
    owner?: string;
    stack?: string;
    stack_id?: string;
    stack_status?: string;
    stack_commit: string;
    created?: string;
    applications: IVerticalApplication[];
    titles?: string[];
    description?: string;
}

export interface IStamp {
    StampName: string;
    StampSubscription: string;
    Verticals: IStackVertical[];
    RolloutServiceGroup: string;
    RolloutId: string;
    RolloutStatus: string;
    RolloutStartTime: string;
    RolloutOwner: string;
    RolloutResources: IStampRolloutResource[];
}

export interface IStackVertical {
    VerticalName: string;
    Clusters: ICluster[];
}

export interface ICluster {
    ClusterName: string;
    EndpointWeight: number;
    ApplicationNodeCount: number;
    DefaultNodeCount: number;
    Applications: IStackApplication[];
}

export interface IStackApplication {
    DeploymentName: string;
    ApplicationName: string;
    Namespace: string;
    DeploymentCreationTime: string;
    ReplicaSets: IReplicaSet[];
}

export interface IReplicaSet {
    ReplicaSetName: string;
    Commit: string;
    ReplicaSetCreationTime: string;
    BuildVersion: string;
    PodStatus: string;
}

export interface ICloudBuildStatusDictionary {
    [cloud: string]: IVerticalBuildStatusDictionary;
}

interface IVerticalBuildStatusDictionary {
    [vertical: string]: IVerticalBuild;
}

export interface IVerticalBuild {
    job: string;
    url: string;
    status: string;
    message: string;
}

export interface IStampRolloutResource {
    RolloutResourceName: string;
    RolloutResourceType: string;
    RolloutResourceState: string;
    RolloutResourceStatusMessage: string;
    RolloutResourceCode: string;
    BicepDeploymentName: string;
}

interface IVerticalApplication {
    application: string;
    commits: IVerticalApplicationCommitProps[];
}

interface IVerticalApplicationCommitProps {
    application: string;
    activation: number;
    commit: string;
    owner: string;
    created: string;
    updated: string;
    elb: {
        instances_inservice: number;
        instances_total: number;
        name: string;
    };
    running_instance_count: number;
    stack: string;
    stack_id: string;
    stack_status: string;
    commits_ahead_master: string;
    commits_behind_master: string;
}

export interface IPipelineRun {
    build_version: string;
    web_link: string;
}

const availableApplicationNames = [
    "mainserver",
    "logicserver",
    "mainservercloudscriptint",
    "mainserverplaystreamint",
    "mainserverinsightsproxy",
    "mainservermatchproxy",
    "gamemanager",
    "queueprocessor",
    "taskprocessor",
    "metricsprocessor",
    "matchmakerprimary",
    "matchmakersecondary",
    "playerportal",
];

// Non Playstream applications that are not shown in the non-base verticals (non-cloud vertical)
const applicationsHiddenFromNonBaseVerticals = [
    "mainserverinsightsproxy",
];

export interface IHomeState {
    isClassicView: boolean;
    isApplicationView: boolean;
    selectedStamp: string;
    selectedStackVertical: string;
    stamps: IStamp[];
    selectedCloud: string;
    selectedVertical: string;
    clouds: ICloud[];
    buddyPipelineRuns: IPipelineRun[];
    officialPipelineRuns: IPipelineRun[];
    latestCloudSummaryGeneratedDate: string;
    latestStampSummaryGeneratedDate: string;
    searchFilter: string;
    showProductionOnlyFilter: boolean;
    collapsedClouds: string[];
    collapsedStamps: string[];
    autoRefresh: boolean;
    buildStatusDictionary: ICloudBuildStatusDictionary;
    darkMode: boolean;
}

const initialState: IHomeState = {
    isClassicView: localStorage.getItem("classic-view") === "true",
    isApplicationView: localStorage.getItem("application-view") === "true",
    selectedStamp: localStorage.getItem("selected-stamp") || "main",
    selectedStackVertical: localStorage.getItem("selected-stackvertical") || "main",
    stamps: [],
    selectedCloud: localStorage.getItem("selected-cloud") || "main",
    selectedVertical: localStorage.getItem("selected-vertical") || "master",
    clouds: [],
    buddyPipelineRuns: [],
    officialPipelineRuns: [],
    latestCloudSummaryGeneratedDate: "",
    latestStampSummaryGeneratedDate: "",
    searchFilter: localStorage.getItem("search-verticals") || "",
    showProductionOnlyFilter: localStorage.getItem("filter-production-only") === "true",
    collapsedClouds: localStorage.getItem("collapsed-clouds") ?
        (localStorage.getItem("collapsed-clouds") || "").split(",") : [],
    collapsedStamps: localStorage.getItem("collapsed-stamps") ?
        (localStorage.getItem("collapsed-stamps") || "").split(",") : [],
    autoRefresh: true,
    buildStatusDictionary: {},
    darkMode: localStorage.getItem("dark-mode") === "true",
};

export default function cloudReducer(state: IHomeState = initialState, action: any): IHomeState {
    switch (action.type) {
        case LOAD_VERTICALS:
            return {
                ...state,
                clouds: (!Is.Null(state.clouds) && state.clouds.length === 0) ? [] : state.clouds,
            };
        case LOAD_VERTICALS_SUCCESS:
            const clouds = {};
            const availAppNames = availableApplicationNames.map((elem) => elem);
            action.data.verticals.forEach((vertical) => {
                if (clouds[vertical.cloud] === undefined) {
                    clouds[vertical.cloud] = [];
                }
                clouds[vertical.cloud].push(vertical);
                vertical.applications.forEach((app) => {
                    if (availAppNames.indexOf(app.application) === -1) {
                        availAppNames.push(app.application);
                    }
                });
            });
            action.data.verticals.forEach((vertical) => {
                let missingApps = [] as string[];

                const verticalAppNames = vertical.applications.map((app: IVerticalApplication) => {
                    return app.application;
                });

                if (!Is.Null(verticalAppNames)) {
                    missingApps = availAppNames.filter((appName: string) => {
                        if (verticalAppNames.indexOf(appName) === -1) {
                            return true;
                        }

                        return false;
                    });
                } else {
                    missingApps = availAppNames;
                }

                if (!Is.Null(missingApps)) {
                    missingApps.forEach((app: string) => {
                        // Omit playstream apps for verticals that aren't master or
                        // a cloud vertical
                        if (!(vertical.cloud !== vertical.vertical &&
                            vertical.vertical !== "master" &&
                            (app.indexOf("playstream") !== -1 || applicationsHiddenFromNonBaseVerticals.indexOf(app) > -1))) {
                            vertical.applications.push({
                                application: app,
                                vertical: vertical.vertical,
                                immutable: false,
                                commits: [],
                            });
                        }
                    });
                }

                vertical.applications.sort((a, b) => VerticalToolsSort.sortApps(a.application, b.application));
            });
            const cloudList: ICloud[] = Object.keys(clouds)
                .map((cloudName) => {
                    return {
                        cloud: cloudName,
                        verticals: clouds[cloudName].sort(VerticalToolsSort.sortVerticals),
                    };
                });
            cloudList.sort(VerticalToolsSort.sortClouds);
            return {
                ...state,
                clouds: cloudList,
                latestCloudSummaryGeneratedDate: action.data.generated,
            };
        case LOAD_VERTICALS_ERROR:
            return {
                ...state,
                clouds: [],
            };
        case LOAD_VERTICAL_BUILDING_STATUS_SUCCESS:
            return {
                ...state,
                buildStatusDictionary: {
                    ...state.buildStatusDictionary,
                    [action.cloud]: {
                        ...state.buildStatusDictionary[action.cloud],
                        [action.vertical]: action.data,
                    },
                },
            };
        case LOAD_VERTICAL_BUILDING_STATUS_ERROR:
            return {
                ...state,
            };
        case LOAD_STAMPS:
            return {
                ...state,
                stamps: (!Is.Null(state.stamps) && state.stamps.length === 0) ? [] : state.stamps,
            };
        case LOAD_STAMPS_SUCCESS:
            const stampList: IStamp[] = [];
            action.data.Stamps.forEach((stamp) => {
                const verticalMapping = { [stamp.StampName]: {} };
                const clusterMapping = {};
                (Is.Null(stamp.Clusters) ? [] : stamp.Clusters).forEach((cluster) => {
                    clusterMapping[cluster.ClusterName] = cluster;
                    cluster.Deployments.forEach((deployment) => {
                        if (verticalMapping[deployment.VerticalName] === undefined) {
                            verticalMapping[deployment.VerticalName] = {}
                        }
                        if (verticalMapping[deployment.VerticalName][cluster.ClusterName] === undefined) {
                            verticalMapping[deployment.VerticalName][cluster.ClusterName] = [] as IStackApplication[];
                        }
                        verticalMapping[deployment.VerticalName][cluster.ClusterName].push(deployment);
                    });
                });
                const verticals = Object.keys(verticalMapping).map((verticalName) => {
                    return {
                        VerticalName: verticalName,
                        Clusters: Object.keys(verticalMapping[verticalName]).map((clusterName) => {
                            return {
                                ClusterName: clusterName,
                                EndpointWeight: clusterMapping[clusterName].EndpointWeight,
                                ApplicationNodeCount: clusterMapping[clusterName].ApplicationNodeCount,
                                DefaultNodeCount: clusterMapping[clusterName].DefaultNodeCount,
                                Applications: verticalMapping[verticalName][clusterName]
                            } as ICluster;
                        }) as ICluster[]
                    } as IStackVertical;
                });

                var result = {
                    StampName: stamp.StampName,
                    StampSubscription: stamp.StampSubscription,
                    Verticals: verticals,
                    RolloutId: "",
                    RolloutStatus: "",
                    RolloutStartTime: "",
                    RolloutOwner: "",
                    RolloutServiceGroup: "",
                    RolloutResources: [],
                } as IStamp;

                if (!Is.Null(stamp.Rollout)) {
                    const rolloutResources = stamp.Rollout.ResourceGroups.flatMap((resourceGroup) => {
                        const actions = resourceGroup.Resources.flatMap((resource) => {
                            const actions = resource.Actions.flatMap((action) => {
                                const actions = action.ResourceOperations.map((operation) => {
                                    return {
                                        RolloutResourceName: operation.ResourceName,
                                        RolloutResourceType: operation.ResourceType,
                                        RolloutResourceState: operation.ProvisioningState,
                                        RolloutResourceStatusMessage: operation.StatusMessage,
                                        RolloutResourceCode: operation.StatusCode,
                                        BicepDeploymentName: action.ActionOperationInfo.DeploymentName,
                                    } as IStampRolloutResource;
                                });
                                return actions
                            });
                            return actions
                        });
                        return actions
                    });
                    result.RolloutId = stamp.Rollout.RolloutId;
                    result.RolloutStatus = stamp.Rollout.Status;
                    result.RolloutStartTime = stamp.Rollout.RolloutOperationInfo.StartTime;
                    result.RolloutOwner = stamp.Rollout.RolloutDetails.Submitter;
                    result.RolloutServiceGroup = stamp.Rollout.RolloutDetails.ServiceGroup;
                    result.RolloutResources = rolloutResources;
                }

                stampList.push(result);
            });
            return {
                ...state,
                stamps: stampList,
                latestStampSummaryGeneratedDate: action.data.Generated,
            };
        case LOAD_STAMPS_ERROR:
            return {
                ...state,
                stamps: [],
            };
        case LOAD_BUDDYPIPELINERUNS:
            return {
                ...state,
                buddyPipelineRuns: (!Is.Null(state.buddyPipelineRuns) && state.buddyPipelineRuns.length === 0) ? [] : state.buddyPipelineRuns,
            };
        case LOAD_BUDDYPIPELINERUNS_SUCCESS:
            return {
                ...state,
                buddyPipelineRuns: action.data.value.map((run) => {
                    return {
                        build_version: run.name,
                        web_link: run._links.web.href,
                    }
                }),
            };
        case LOAD_BUDDYPIPELINERUNS_ERROR:
            return {
                ...state,
                buddyPipelineRuns: [],
            };
        case LOAD_OFFICIALPIPELINERUNS:
            return {
                ...state,
                officialPipelineRuns: (!Is.Null(state.officialPipelineRuns) && state.officialPipelineRuns.length === 0) ? [] : state.officialPipelineRuns,
            };
        case LOAD_OFFICIALPIPELINERUNS_SUCCESS:
            return {
                ...state,
                officialPipelineRuns: action.data.value.map((run) => {
                    return {
                        build_version: run.name,
                        web_link: run._links.web.href,
                    }
                }),
            };
        case LOAD_OFFICIALPIPELINERUNS_ERROR:
            return {
                ...state,
                officialPipelineRuns: [],
            };
        case SET_SELECTED_STAMP_VERTICAL:
            localStorage.setItem("selected-stamp", action.stamp);
            localStorage.setItem("selected-stackvertical", action.vertical);
            return {
                ...state,
                selectedStamp: action.stamp,
                selectedStackVertical: action.vertical,
            };
        case SET_SELECTED_CLOUD_VERTICAL:
            localStorage.setItem("selected-cloud", action.cloud);
            localStorage.setItem("selected-vertical", action.vertical);
            return {
                ...state,
                selectedCloud: action.cloud,
                selectedVertical: action.vertical,
            };
        case SET_SEARCH_FILTER:
            localStorage.setItem("search-verticals", action.query);
            return {
                ...state,
                searchFilter: action.query,
            };
        case SET_PRODUCTION_ONLY_FILTER:
            localStorage.setItem("filter-production-only", action.checked.toString());
            return {
                ...state,
                showProductionOnlyFilter: action.checked,
            };
        case SET_CLASSIC_VIEW:
            localStorage.setItem("classic-view", action.checked.toString());
            return {
                ...state,
                isClassicView: action.checked,
            };
        case SET_APPLICATION_VIEW:
            localStorage.setItem("application-view", action.checked.toString());
            return {
                ...state,
                isApplicationView: action.checked,
            };
        case TOGGLE_COLLAPSE:
            const newCollapseClouds = state.collapsedClouds.splice(0);
            const cloudIndex = newCollapseClouds.indexOf(action.cloud);
            if (cloudIndex >= 0) { // Remove if found
                newCollapseClouds.splice(cloudIndex, 1);
            } else { // Add if not found
                newCollapseClouds.push(action.cloud);
            }
            localStorage.setItem("collapsed-clouds", newCollapseClouds.join(","));

            const newCollapseStamps = state.collapsedStamps.splice(0);
            const stampIndex = newCollapseStamps.indexOf(action.stamp);
            if (stampIndex >= 0) { // Remove if found
                newCollapseStamps.splice(stampIndex, 1);
            } else { // Add if not found
                newCollapseStamps.push(action.stamp);
            }
            localStorage.setItem("collapsed-stamps", newCollapseStamps.join(","));
            return {
                ...state,
                collapsedClouds: newCollapseClouds,
                collapsedStamps: newCollapseStamps,
            };
        case TOGGLE_ALL_COLLAPSE:
            // If none are collapsed, then collapse all, otherwise expand all
            const newAllCollapseClouds = (state.collapsedClouds.length === 0) ? state.clouds.map((cloud) => cloud.cloud) : [];
            localStorage.setItem("collapsed-clouds", newAllCollapseClouds.join(","));

            const newAllCollapseStamps = (state.collapsedStamps.length === 0) ? state.stamps.map((stamp) => stamp.StampName) : [];
            localStorage.setItem("collapsed-stamps", newAllCollapseStamps.join(","));
            return {
                ...state,
                collapsedClouds: newAllCollapseClouds,
                collapsedStamps: newAllCollapseStamps,
            };
        case TOGGLE_AUTO_REFRESH:
            return {
                ...state,
                autoRefresh: !state.autoRefresh,
            };
        case TOGGLE_DARK_MODE:
            localStorage.setItem("dark-mode", (!state.darkMode).toString());
            return {
                ...state,
                darkMode: !state.darkMode,
            };
        default:
            return state;
    }
}
