import { Icon } from "office-ui-fabric-react/lib/Icon";
import * as React from "react";

import { Is } from "../utils/Base";
import styled from "../utils/localStyledComponents";
import VerticalStatus from "./VerticalStatus";

const NavGroupItemButton = styled.button<{selected: boolean}>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 2px solid ${(props) => props.selected ? props.theme.color.playfabOrange : "transparent"};
    padding: 0 3px 0 3px;
    height: 36px;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: ${(props) => props.theme.color.linkFont};

    background-color: ${(props) => props.selected ? props.theme.color.primaryNavHighlight : props.theme.color.primaryNavBackground}

    &:hover {
        color: ${(props) => props.theme.color.normalFont};
        background-color: ${(props) => props.theme.color.primaryNavHighlight};
    }

    &:focus {
        outline: none;
    }
`;

const NavGroupItemDayCounter = styled.div<{days: number}>`
    color: #fff;
    text-align: center;
    padding: 3px;
    margin-right: 3px;
    border-radius: 50%;
    border: 1px solid transparent;
    min-width: 24px;
    background-color: ${(props) => props.days >= 30 ? props.theme.color.failed : props.days >= 8 ? props.theme.color.warning : props.theme.color.success};
`;

const SecondaryIcon = styled(Icon)`
    font-size: 16px;
    margin: 0 5px 0 2.5px;
`;

const ItemSpan = styled.span`
    margin-left: 3px;
`;

interface INavGroupProps {
    text: string;
    selected: boolean;
    days?: number;
    stackStatus: string;
    buildStatus?: string;
    onClick: (event) => void;
}

export default class NavGroupItem extends React.PureComponent<INavGroupProps> {
    public render(): JSX.Element {
        let daysDiffCounter: JSX.Element = (<div />);
        if (!Is.Null(this.props.days) && this.props.days! >= 5) {
            daysDiffCounter = (
                <NavGroupItemDayCounter days={this.props.days!}>
                    {this.props.days}
                </NavGroupItemDayCounter>
            );
        }
        return (
            <NavGroupItemButton
                selected={this.props.selected}
                onClick={this.props.onClick}
            >
                <div>
                    {<VerticalStatus status={this.props.stackStatus} />}
                    {this.getBuildIcon()}
                    <ItemSpan>{this.props.text}</ItemSpan>
                </div>
                {daysDiffCounter}
            </NavGroupItemButton>
        );
    }

    private getBuildIcon = () => {
        if (this.props.buildStatus === "running") {
            return (<SecondaryIcon iconName="Build" styles={{root: {color: "#b8b8b8"}}} />);
        } else if (this.props.buildStatus === "success") {
            return (<SecondaryIcon iconName="Completed" styles={{root: {color: "#21ba45"}}} />);
        } else if (this.props.buildStatus === "error") {
            return (<SecondaryIcon iconName="IncidentTriangle" styles={{root: {color: "#db2828"}}} />);
        }
        return null;
    }
}
