import { Checkbox, Dialog } from "office-ui-fabric-react";
import { DefaultButton, IconButton, PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { CommandBar, ICommandBarItemProps } from "office-ui-fabric-react/lib/CommandBar";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import * as React from "react";

import { IVertical, IVerticalBuild } from "../containers/HomePage/reducer";
import { DateTime, Is } from "../utils/Base";
import styled, { BuildA, BuildWrapper, ContentHeaderDiv, ContentHeaderTitle, ContentHeaderWrapper, ITheme, InlineBlockDiv, VerticalDetail, VerticalDetailsWrapper, withTheme } from "../utils/localStyledComponents";
import { VerticalToolsJenkins, VerticalToolsUrl } from "../utils/VerticalTools";
import CFNStackLink from "./CFNStackLink";
import VerticalStatus from "./VerticalStatus";
import { createCommandBarItem } from './Shared/Helpers';

interface IVerticalHeaderProps {
    theme: ITheme;
    vertical: IVertical;
    verticalBuild?: IVerticalBuild;
}

interface IState {
    addAppsDialogIsVisible: boolean;
    addAppsSelectedApps: string[];
    activateAppsDialogIsVisible: boolean;
    activateAppsSelectedApps: string[];
}

export class VerticalHeader extends React.PureComponent<IVerticalHeaderProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            addAppsDialogIsVisible: false,
            addAppsSelectedApps: [],
            activateAppsDialogIsVisible: false,
            activateAppsSelectedApps: [],
        };
    }

    public render(): JSX.Element {
        if (!this.props.vertical) {
            return (<Spinner size={SpinnerSize.large} />);
        }
        const verticalCommandBarStyles = {
            root: {
                backgroundColor: this.props.theme.color.background,
                borderBottom: `1px solid ${this.props.theme.color.border}`,
            },
        };

        let titleString = "";
        if (!Is.Null(this.props.vertical.titles)) {
            titleString = (this.props.vertical.titles || []).reduce((titleStr, currentTitle) =>
                titleStr.length === 0
                    ? currentTitle
                    : `${titleStr}, ${currentTitle}`,
                "");
        }

        return (
            <div>
                <ContentHeaderWrapper>
                    <div>
                        <ContentHeaderDiv>
                            <VerticalStatus isBig status={this.props.vertical.stack_status || ""} />
                            <ContentHeaderTitle>{this.props.vertical.vertical}</ContentHeaderTitle>
                        </ContentHeaderDiv>
                        {(!Is.Null(this.props.vertical.titles)) && (
                            <div>
                                <strong>Titles: </strong>
                                {titleString}
                            </div>
                        )}
                        <div>
                            <strong>Description: </strong>
                            {this.props.vertical.description}
                        </div>
                        {VerticalToolsUrl.isProductionVertical(this.props.vertical.cloud, this.props.vertical.vertical) && (
                            <div>
                                <a href="https://microsoft.visualstudio.com/GDX/_wiki/wikis/GDX/65272/Production-Verticals" target="_blank" rel="noopener noreferrer">More detailed description</a>
                            </div>
                        )}
                    </div>
                    <VerticalDetailsWrapper>
                        <VerticalDetail>
                            <strong>CFN Stack: </strong>
                            <CFNStackLink
                                stackId={this.props.vertical.stack_id || ""}
                                stackStatus={this.props.vertical.stack_status || ""}
                            />
                        </VerticalDetail>
                        <VerticalDetail>
                            <strong>Owner: </strong>
                            {this.props.vertical.owner}
                        </VerticalDetail>
                        <VerticalDetail>
                            <strong>Created: </strong>
                            {DateTime.ToReadableDate(this.props.vertical.created || "")}
                        </VerticalDetail>
                    </VerticalDetailsWrapper>
                </ContentHeaderWrapper>
                {this.getBuildContent()}
                <CommandBar
                    styles={verticalCommandBarStyles}
                    items={this.getVerticalCommandBarActions()}
                />
                <Dialog
                    dialogContentProps={{title: "Add applications"}}
                    hidden={!this.state.addAppsDialogIsVisible}
                    onDismiss={this.hideAddApps}
                >
                    <div className="checkbox-dialog-group">
                        {this.props.vertical.applications.map((app) => {
                            return (<Checkbox key={app.application} checked={this.state.addAppsSelectedApps.some((s) => s === app.application)} onChange={this.onAddAppsCheckChange.bind(this, app.application)} label={app.application}/>);
                        })}
                    </div>
                    <div className="dialog-buttons">
                        <PrimaryButton onClick={this.addAppsConfirm}>Submit</PrimaryButton>
                        <DefaultButton primary={false} onClick={this.hideAddApps}>Cancel</DefaultButton>
                    </div>
                </Dialog>
                <Dialog
                    dialogContentProps={{title: "Activate applications"}}
                    hidden={!this.state.activateAppsDialogIsVisible}
                    onDismiss={this.hideActivateApps}
                >
                    <div className="checkbox-dialog-group">
                        {this.props.vertical.applications.map((app) => {
                            return (<Checkbox key={app.application} checked={this.state.activateAppsSelectedApps.some((s) => s === app.application)} onChange={this.onActivateAppsCheckChange.bind(this, app.application)} label={app.application}/>);
                        })}
                    </div>
                    <div className="dialog-buttons">
                        <PrimaryButton onClick={this.activateAppsConfirm}>Submit</PrimaryButton>
                        <DefaultButton primary={false} onClick={this.hideActivateApps}>Cancel</DefaultButton>
                    </div>
                </Dialog>
            </div>
        );
    }

    private getVerticalCommandBarActions = () => {
        const appActionCommandBarItems: ICommandBarItemProps[] = [
            createCommandBarItem("Apps", undefined, "App: ", undefined, undefined, undefined, this.props.theme.color),
            createCommandBarItem("Add", "Add", undefined, "Add", this.showAddApps, undefined, this.props.theme.color),
            createCommandBarItem("Activate", "Activate", undefined, "PowerButton", this.showActivateApps, undefined, this.props.theme.color),
            createCommandBarItem("CleanUp", "Clean up", undefined, "Broom", undefined, VerticalToolsJenkins.JobStartFormUrl("cleanup-applications", [
                ["Vertical", this.props.vertical.vertical],
            ]), this.props.theme.color),
        ];

        const verticalActionCommandBarItems: ICommandBarItemProps[] = [
            createCommandBarItem("Verticals", undefined, "Vertical: ", undefined, undefined, undefined, this.props.theme.color),
            createCommandBarItem(
                "FullUpdate",
                "Full update",
                undefined,
                "StepSharedAdd",
                undefined,
                VerticalToolsJenkins.JobStartFormUrl(
                    "full-vertical-update",
                    [
                        ["Vertical", this.props.vertical.vertical],
                        ["Rebase", (this.props.vertical.vertical !== "master").toString()],
                        ["Cleanup", (this.props.vertical.vertical !== "master").toString()],
                    ],
                ),
                this.props.theme.color
            ),
            createCommandBarItem(
                "UpdateStack",
                "Update stack",
                undefined,
                "ExploreContent",
                undefined,
                VerticalToolsJenkins.JobStartFormUrl(
                    "update-vertical", [
                        ["Vertical", this.props.vertical.vertical],
                    ],
                ),
                this.props.theme.color
            ),
            createCommandBarItem("Delete", "Delete",  undefined, "Delete", undefined, VerticalToolsJenkins.JobStartFormUrl("remove-vertical", [
                ["Vertical", this.props.vertical.vertical],
                ["Commit", this.props.vertical.stack_commit],
            ]), this.props.theme.color),
        ];

        return appActionCommandBarItems.concat(verticalActionCommandBarItems);
    }

    private getBuildContent = () => {
        let buildContent = (<div />);
        if (this.props.verticalBuild && this.props.verticalBuild.status !== "complete") {
            buildContent = (
                <BuildWrapper status={this.props.verticalBuild.status}>
                    <InlineBlockDiv>
                        <strong>Build Status: </strong>
                        <BuildA href={this.props.verticalBuild.url} target="_blank">{this.props.verticalBuild.status}</BuildA>
                    </InlineBlockDiv>
                    <InlineBlockDiv>
                        <strong>Job: </strong>
                        {this.props.verticalBuild.job}
                    </InlineBlockDiv>
                    <InlineBlockDiv>
                        <strong>Step: </strong>
                        {this.props.verticalBuild.message}
                    </InlineBlockDiv>
                    <IconButton iconProps={{iconName: "Trash"}} onClick={this.openClearBuildStatus}/>
                </BuildWrapper>
            );
        }
        return buildContent;
    }

    private openClearBuildStatus = () => {
        window.open(VerticalToolsJenkins.JobStartFormUrl("clear-build-status", [
                        ["Vertical", this.props.vertical.vertical],
                    ]), "_blank");
    }

    private showAddApps = () => {
        this.setState({
            ...this.state,
            addAppsDialogIsVisible: true,
            addAppsSelectedApps: [],
        });
    }

    private hideAddApps = () => {
        this.setState({
            ...this.state,
            addAppsDialogIsVisible: false,
            addAppsSelectedApps: [],
        });
    }

    private addAppsConfirm = () => {
        window.open(VerticalToolsJenkins.JobStartFormUrl("add-applications", [
            ["Branch", this.props.vertical.vertical],
            ["Applications", this.state.addAppsSelectedApps.join(",")],
        ]));
        this.setState({
            ...this.state,
            addAppsDialogIsVisible: false,
            addAppsSelectedApps: [],
        });
    }

    private onAddAppsCheckChange = (application: string, ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        if (checked) {
            this.setState({
                ...this.state,
                addAppsSelectedApps: this.state.addAppsSelectedApps.concat([application]),
            });
        } else {
            this.setState({
                ...this.state,
                addAppsSelectedApps: this.state.addAppsSelectedApps.filter((a) => a !== application),
            });
        }
    }

    private showActivateApps = () => {
        this.setState({
            ...this.state,
            activateAppsDialogIsVisible: true,
            activateAppsSelectedApps: [],
        });
    }

    private hideActivateApps = () => {
        this.setState({
            ...this.state,
            activateAppsDialogIsVisible: false,
            activateAppsSelectedApps: [],
        });
    }

    private activateAppsConfirm = () => {
        window.open(VerticalToolsJenkins.JobStartFormUrl("activate-applications", [
            ["Branch", this.props.vertical.vertical],
            ["Applications", this.state.addAppsSelectedApps.join(",")],
        ]));
        this.setState({
            ...this.state,
            activateAppsDialogIsVisible: false,
            activateAppsSelectedApps: [],
        });
    }

    private onActivateAppsCheckChange = (application: string, ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        if (checked) {
            this.setState({
                ...this.state,
                activateAppsSelectedApps: this.state.activateAppsSelectedApps.concat([application]),
            });
        } else {
            this.setState({
                ...this.state,
                activateAppsSelectedApps: this.state.activateAppsSelectedApps.filter((a) => a !== application),
            });
        }
    }
}

export default withTheme(VerticalHeader);
