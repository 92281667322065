import { ActionButton } from "office-ui-fabric-react";
import * as React from "react";

import { IReactEnumable, Is, IStringDictionary } from "../../utils/Base";
import { VerticalToolsJenkins, VerticalToolsUrl } from "../../utils/VerticalTools";
import {IVerticalApplicationCommitProps, VerticalApplicationCommit} from "./VerticalApplicationCommit";
import { CardWrapper } from "../../utils/localStyledComponents";

export interface IVerticalApplication extends IReactEnumable {
    application: string;
    vertical: string;
    cloud: string;
    commits: IVerticalApplicationCommitProps[];
    addApplicationCallback: (arg: string) => void;
}

export class VerticalApplication extends React.PureComponent<IVerticalApplication> {
    private availableColors: IStringDictionary = {
        gamemanager: "red",
        logicserver: "orange",
        mainserver: "yellow",
        mainserver_stack: "yellow",
        mainservercloudscriptint: "yellow",
        mainserverplaystreamint: "yellow",
        mainservermatchproxy: "yellow",
        matchmakerprimary: "olive",
        matchmakersecondary: "green",
        legacymatchmaker: "olive",
        playerportal: "teal",
        playerportal_stack: "teal",
        playstreamentityprofileprocessor: "violet",
        actionprocessor: "violet",
        queueprocessor: "purple",
        queueprocessor_stack: "purple",
        taskprocessor_stack: "purple",
        metricsprocessor: "purple",
    };

    public render() {
        const appColor: string = this.availableColors[this.props.application] || "blue";
        const cardClassName = `ui card vertical-application ${appColor}`;
        const headerLink = VerticalToolsUrl.applicationRoute(this.props.vertical, this.props.application, this.props.cloud);
        const header = Is.Null(headerLink)
            ? this.props.application
            : (<a href={headerLink} target="_blank" rel="noopener noreferrer">{this.props.application}</a>);

        let commitElements: JSX.Element = (<div />);
        if (!Is.Null(this.props.commits)) {
            commitElements = (
                <div>
                    {this.props.commits.map((commit: IVerticalApplicationCommitProps) => {
                        return (
                            <VerticalApplicationCommit
                                key={commit.jsxkey}
                                vertical={this.props.vertical}
                                cloud={this.props.cloud}
                                application={this.props.application}
                                region={commit.region}
                                activation={commit.activation}
                                commit={commit.commit}
                                created={commit.created}
                                owner={commit.owner}
                                running_instance_count={commit.running_instance_count}
                                stack={commit.stack}
                                stack_id={commit.stack_id}
                                stack_status={commit.stack_status}
                                commits_ahead_master={commit.commits_ahead_master}
                                commits_behind_master={commit.commits_behind_master}
                                elb={commit.elb}
                            />
                        );
                    })}
                </div>
            );
        }

        const addStackButton = (this.canAddCommit()
            ?  (
            <ActionButton
                iconProps={{iconName: "add"}}
                onClick={this.provisionCommit}
            >
                Add Stack
            </ActionButton>
            ) : null
        );

        return (
            <CardWrapper>
                <div className={cardClassName}>
                    <div className="content">
                        <div className="header">
                            {header}
                        </div>
                        {commitElements}
                    </div>
                    <div className="extra content application-actions">
                        {addStackButton}
                    </div>
                </div>
            </CardWrapper>
        );
    }

    private canAddCommit = (): boolean => {
        if (Is.Null(this.props.commits) || this.props.commits!.length <= 1) {
            return true;
        }

        return false;
    }

    private provisionCommit = (): void => {
        this.props.addApplicationCallback(this.props.application);
    }

    private updateCommit = (): void => {
        window.open(VerticalToolsJenkins.JobStartFormUrl("update-application", [
            ["Vertical", this.props.vertical],
            ["Application", this.props.application],
        ]));
    }
}
