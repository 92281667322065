import { createGlobalStyle } from "./utils/localStyledComponents";

export default createGlobalStyle`
    html, body {
        height: 100%;
        width: 100%;
    }
    body {
        font-family: "Open Sans", Helvetica, Arial, sans-serif;
        overflow-x: auto;
        margin: 0;
    }
    #app {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        min-width: 100%;
    }

    /* TODO: remove semantic css below */
    a {
        color: ${(props) => props.theme.color.linkFont};
        text-decoration: none;
    }

    .ui.card.vertical-application, .ui.stackcard.stamp-application, .ui.stackclustercard.stamp-cluster {
        margin: 10px;
        background-color: ${(props) => props.theme.color.cardBackground};
        border: 3px solid ${(props) => props.theme.color.cardBorder};
        box-shadow: none;
    }

    .ui.stackclustercard {
        width: 100%;
        border-radius: .28571429rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
    }

    .ui.card {
        width: 290px;
        border-radius: .28571429rem;
    }

    .ui.stackcard {
        width: 440px;
        border-radius: .28571429rem;
    }

    .ui.card > .content, .ui.stackcard > .content, .ui.stackclustercard > .content {
        padding: 1em 1em;
    }

    .ui.card.vertical-application:hover, .ui.stackcard.stamp-application:hover, .ui.stackclustercard.stamp-cluster:hover {
        box-shadow: none;
    }

    .ui.header {
        border: none;
        margin: 0 0 1rem;
        padding: 0 0;
        line-height: 1.28571429em;
        text-transform: none;
        color: rgba(0,0,0,.87);
    }

    .ui.header:first-child {
        margin-top: -.14285714em;
    }

    .ui.header .content {
        display: inline-block;
        vertical-align: top;
    }

    div.ui.segment.stamp-application-cluster {
        margin: 10px;
        background-color: ${(props) => props.theme.color.cardBackground};
        border: 2px solid ${(props) => props.theme.color.cardBorder};
        border-radius: .28571429rem;
        padding: 1em 1em;
    }

    div.ui.segment.stamp-cluster-application {
        width: 290px;
        margin: 10px;
        background-color: ${(props) => props.theme.color.cardBackground};
        border: 2px solid ${(props) => props.theme.color.cardBorder};
        border-radius: .28571429rem;
        padding: 1em 1em;
    }

    div.ui.segment.vertical-application-commit, div.ui.segment.stamp-application-replicaset {
        margin: 10px;
        background-color: ${(props) => props.theme.color.cardBackground};
        border: 1px solid ${(props) => props.theme.color.cardBorder};
        border-radius: .28571429rem;
        padding: 1em 1em;
    }

    div.ui.segment.vertical-application-commit table, 
    div.ui.segment.stamp-application-cluster table, 
    div.ui.segment.stamp-application-replicaset table,
    div.ui.segment.stamp-cluster table,
    div.ui.segment.stamp-cluster-application table {
        width: 100%;
    }

    div.ui.segment.vertical-application-commit table th {
        text-align: right;
        padding-right: 25px;
        vertical-align: top;
        color: ${(props) => props.theme.color.normalFont};
    }

    div.ui.segment.stamp-application-cluster table th, 
    div.ui.segment.stamp-application-replicaset table th,
    div.ui.segment.stamp-cluster table th,
    div.ui.segment.stamp-cluster-application table th {
        text-align: right;
        padding-right: 15px;
        vertical-align: top;
        color: ${(props) => props.theme.color.normalFont};
    }

    div.ui.segment.vertical-application-commit table th.hidden, 
    div.ui.segment.stamp-application-cluster table th.hidden, 
    div.ui.segment.stamp-application-replicaset table th.hidden,
    div.ui.segment.stamp-cluster table th.hidden,
    div.ui.segment.stamp-cluster-application table th.hidden {
        color: white;
    }

    div.ui.segment.vertical-application-commit table td {
        width: 100%;
        color: ${(props) => props.theme.color.normalFont};
    }

    div.ui.segment.stamp-application-cluster table td, 
    div.ui.segment.stamp-application-replicaset table td,
    div.ui.segment.stamp-cluster table td,
    div.ui.segment.stamp-cluster-application table td {
        width: 50%;
        color: ${(props) => props.theme.color.normalFont};
    }

    div.active-percentage {
        width: 100%;
        text-align: center;
    }

    div.ui.mini.statistic.active-percentage div.value, div.ui.mini.statistic.active-percentage div.label {
        color: ${(props) => props.theme.color.normalFont};
    }

    div.commit-helpers {
        float: right;
        padding-left: 1rem;
    }

    div.application-helpers {
        float: right;
        padding-left: 1rem;
        margin: 10px;
    }

    div.cluster-helpers {
        position: absolute;
        right: 10px;
    }

    div.ui.vertical-application div.content div.header, div.ui.stamp-application div.content div.header  {
        color: ${(props) => props.theme.color.normalFont};
    }

    h4.ui.header div.content {
        color: ${(props) => props.theme.color.normalFont};
    }

    button.ms-Button--action {
        color: ${(props) => props.theme.color.normalFont};
    }

    div.commits {
        display: flex;
        justify-content: space-between;
    }

    div.ms-ContextualMenu-Callout {
        background-color: ${(props) => props.theme.color.cardBackground};
    }

    li.ms-ContextualMenu-item:hover .ms-ContextualMenu-link {
        background-color: ${(props) => props.theme.color.primaryNavHighlight};
        color: ${(props) => props.theme.color.linkFont};
    }

    li.ms-ContextualMenu-item {
        color: ${(props) => props.theme.color.linkFont};
        text-decoration: none;
    }

    div.checkbox-dialog-group > div, div.branch-dialog-group > div {
        padding-bottom: 0.25rem;
    }

    div.dialog-buttons {
        padding-top: .5rem;
        display: flex;
        justify-content: space-between;
    }
`;
