import { all } from "redux-saga/effects";

import { onLoadVerticalBuildingStatus, onLoadVerticals, onLoadStamps, onLoadBuddyPipelineRuns, onLoadOfficialPipelineRuns } from "./containers/HomePage/saga";

export default function* rootSaga() {
    yield all([
        onLoadVerticalBuildingStatus(),
        onLoadVerticals(),
        onLoadStamps(),
        onLoadBuddyPipelineRuns(),
        onLoadOfficialPipelineRuns(),
    ]);
}
