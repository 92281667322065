import { DefaultButton, IButtonStyles } from "office-ui-fabric-react/lib/Button";
import * as React from "react";

import styled, { ITheme, withTheme } from "../utils/localStyledComponents";

interface INavGroupButtonProps extends React.AllHTMLAttributes<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement> {
    iconProps?: {
        iconName?: string;
    };
    styles?: IButtonStyles;
    data?: any;
    text?: string;
    uniqueId?: string | number;
}

const NavGroupWrapper = styled.div`
    width: 100%;
`;

const NavGroupButton = styled(DefaultButton)<INavGroupButtonProps>`
    background-color: ${(props) => props.theme.color.primaryNavBackground};
    width: 100%;
    padding-left: .2rem;
    border-bottom: 1px solid ${(props) => props.theme.color.border};
    border-top: none;
    border-left: none;
    border-right: none;
    height: 36px;
    margin: 5px 0;

    &:hover {
        background-color: ${(props) => props.theme.color.primaryNavHighlight};
    }
`;

interface INavGroupProps {
    text: string;
    collapsed: boolean;
    onClick: (event) => void;
    Wrapper?: any;
}

interface IThemeProps {
    theme: ITheme;
}

export class NavGroup extends React.PureComponent<INavGroupProps & IThemeProps> {
    public render(): JSX.Element {
        const Wrapper = this.props.Wrapper ? this.props.Wrapper : NavGroupWrapper;
        const navGroupButtonStyles: IButtonStyles = {
            label: {
                fontWeight: 300,
                fontSize: "17px",
                color: this.props.theme.color.normalFont,
                textAlign: "left",
            },
            icon: {
                color: this.props.theme.color.normalFont,
                fontSize: "12px",
            },
        };
        return (
            <Wrapper>
                <NavGroupButton
                    iconProps={{iconName: this.props.collapsed ? "ChevronDown" : "ChevronUp"}}
                    styles={navGroupButtonStyles}
                    onClick={this.props.onClick}
                >
                    {this.props.text}
                </NavGroupButton>
                {this.props.collapsed ? null : this.props.children}
            </Wrapper>
        );
    }
}

export default withTheme(NavGroup) as React.ComponentType<INavGroupProps>;
