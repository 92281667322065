export const LOAD_VERTICALS = "LOAD_VERTICALS";
export const LOAD_VERTICALS_SUCCESS = "LOAD_VERTICALS_SUCCESS";
export const LOAD_VERTICALS_ERROR = "LOAD_VERTICALS_ERROR";

export const LOAD_VERTICAL_BUILDING_STATUS = "LOAD_VERTICAL_BUILDING_STATUS";
export const LOAD_VERTICAL_BUILDING_STATUS_SUCCESS = "LOAD_VERTICAL_BUILDING_STATUS_SUCCESS";
export const LOAD_VERTICAL_BUILDING_STATUS_ERROR = "LOAD_VERTICAL_BUILDING_STATUS_ERROR";

export const LOAD_STAMPS = "LOAD_STAMPS";
export const LOAD_STAMPS_SUCCESS = "LOAD_STAMPS_SUCCESS";
export const LOAD_STAMPS_ERROR = "LOAD_STAMPS_ERROR";

export const LOAD_BUDDYPIPELINERUNS = "LOAD_BUDDYPIPELINERUNS";
export const LOAD_BUDDYPIPELINERUNS_SUCCESS = "LOAD_BUDDYPIPELINERUNS_SUCCESS";
export const LOAD_BUDDYPIPELINERUNS_ERROR = "LOAD_BUDDYPIPELINERUNS_ERROR";

export const LOAD_OFFICIALPIPELINERUNS = "LOAD_OFFICIALPIPELINERUNS";
export const LOAD_OFFICIALPIPELINERUNS_SUCCESS = "LOAD_OFFICIALPIPELINERUNS_SUCCESS";
export const LOAD_OFFICIALPIPELINERUNS_ERROR = "LOAD_OFFICIALPIPELINERUNS_ERROR";