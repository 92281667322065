import { TOGGLE_AUTO_REFRESH, TOGGLE_DARK_MODE } from "./constants";

export function toggleAutoRefresh() {
    return {
        type: TOGGLE_AUTO_REFRESH,
    };
}

export function toggleDarkMode() {
    return {
        type: TOGGLE_DARK_MODE,
    };
}
