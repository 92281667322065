import * as constants from "./constants";

export function loadVerticals() {
    return {
        type: constants.LOAD_VERTICALS,
    };
}

export function verticalsLoaded(data) {
    return {
        type: constants.LOAD_VERTICALS_SUCCESS,
        data,
    };
}

export function verticalsLoadingError(error) {
    return {
        type: constants.LOAD_VERTICALS_ERROR,
        error,
    };
}

export function loadVerticalBuildingStatus(cloud: string, vertical: string) {
    return {
        type: constants.LOAD_VERTICAL_BUILDING_STATUS,
        cloud,
        vertical,
    };
}

export function verticalBuildingStatusLoaded(cloud: string, vertical: string, data) {
    return {
        type: constants.LOAD_VERTICAL_BUILDING_STATUS_SUCCESS,
        cloud,
        vertical,
        data,
    };
}

export function verticalBuildingStatusLoadingError(cloud: string, vertical: string, error) {
    return {
        type: constants.LOAD_VERTICAL_BUILDING_STATUS_ERROR,
        cloud,
        vertical,
        error,
    };
}

export function loadStamps() {
    return {
        type: constants.LOAD_STAMPS,
    };
}

export function stampsLoaded(data) {
    return {
        type: constants.LOAD_STAMPS_SUCCESS,
        data,
    };
}

export function stampsLoadingError(error) {
    return {
        type: constants.LOAD_STAMPS_ERROR,
        error,
    };
}

export function loadBuddyPipelineRuns() {
    return {
        type: constants.LOAD_BUDDYPIPELINERUNS,
    };
}

export function buddyPipelineRunsLoaded(data) {
    return {
        type: constants.LOAD_BUDDYPIPELINERUNS_SUCCESS,
        data,
    };
}

export function buddyPipelineRunsLoadingError(error) {
    return {
        type: constants.LOAD_BUDDYPIPELINERUNS_ERROR,
        error,
    };
}

export function loadOfficialPipelineRuns() {
    return {
        type: constants.LOAD_OFFICIALPIPELINERUNS,
    };
}

export function officialPipelineRunsLoaded(data) {
    return {
        type: constants.LOAD_OFFICIALPIPELINERUNS_SUCCESS,
        data,
    };
}

export function officialPipelineRunsLoadingError(error) {
    return {
        type: constants.LOAD_OFFICIALPIPELINERUNS_ERROR,
        error,
    };
}