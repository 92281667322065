// tslint:disable jsx-no-lambda
import * as moment from "moment";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { SearchBox } from "office-ui-fabric-react/lib/SearchBox";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { Toggle } from "office-ui-fabric-react/lib/Toggle";
import * as React from "react";
import { connect } from "react-redux";

import NavGroup from "../../components/NavGroup";
import NavGroupItem from "../../components/NavGroupItem";
import styled, { ITheme, withTheme } from "../../utils/localStyledComponents";
import { ICloud, ICloudBuildStatusDictionary, IStamp } from "../HomePage/reducer";
import {
    setProductionOnlyFilter, setSearchFilter, setSelectedCloudVertical, setApplicationView,
    toggleAllCollapse, toggleCollapse, setClassicView, setSelectedStackVertical,
} from "./actions";

const NavWrapper = styled.div`
    background-color: ${(props) => props.theme.color.primaryNavBackground};
    flex-basis: ${(props) => props.theme.size.primaryNavWidth};
    border-right: 1px solid ${(props) => props.theme.color.border};

    > p {
        margin-top: 1em;
    }
`;

const FilterGroupWrapper = styled.div`
    width: ${(props) => props.theme.size.primaryNavWidth};
`;

interface IHeaderSearchBoxProps {
    placeholder: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
    styles: {
        field: {
            color: string;
        },
        icon: {
            color: string;
        },
    };
}

const NavSearchBox = styled(SearchBox) <IHeaderSearchBoxProps>`
    border-color: ${(props) => props.theme.color.border};
    background-color: ${(props) => props.theme.color.primaryNavHighlight};
    color: ${(props) => props.theme.color.normalFont};
    margin: 0 .3rem;
`;

const CenteredWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 24px;
`;

const SpacedWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
`;

const PGeneratedDateTime = styled.p`
    text-align: center;
    font-size: .9em;
    color: ${(props) => props.theme.color.normalFont};
`;

interface IButtonProps extends
    React.AllHTMLAttributes<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement> {
    text?: string;
}

const ToggleAllButton = styled(PrimaryButton) <IButtonProps>`
    margin: 0 auto;
    height: 28px;
    font-size: 10px;
`;

interface IPrimaryNavProps {
    selectedCloud: string;
    selectedVertical: string;
    clouds: ICloud[];
    selectedStamp: string;
    selectedStackVertical: string;
    stamps: IStamp[];
}

interface IPrimaryNavPropsTheme {
    theme: ITheme;
}

interface IStateProps {
    isClassicView: boolean;
    isApplicationView: boolean;
    searchFilter: string;
    showProductionOnlyFilter: boolean;
    collapsedClouds: string[];
    collapsedStamps: string[];
    buildStatusDictionary: ICloudBuildStatusDictionary;
    latestCloudSummaryGeneratedDate: string;
    latestStampSummaryGeneratedDate: string;
}

interface IActionProps {
    setClassicView: (checked: boolean) => void;
    setApplicationView: (checked: boolean) => void;
    setProductionOnlyFilter: (checked: boolean) => void;
    setSearchFilter: (event: React.ChangeEvent<HTMLInputElement>, query: string) => void;
    setSelectedCloudVertical: (cloud: string, vertical: string) => void;
    setSelectedStackVertical: (stamp: string, vertical: string) => void;
    toggleCollapse: (cloud: string, stamp: string) => void;
    toggleAllCollapse: () => void;
}

type Props = IPrimaryNavProps & IPrimaryNavPropsTheme & IStateProps & IActionProps;

interface IPrimaryNavState {
    isFilterColllapsed: boolean;
}

export class PrimaryNav extends React.PureComponent<Props, IPrimaryNavState> {
    constructor(props) {
        super(props);
        this.state = {
            isFilterColllapsed: false,
        };
    }

    public render(): JSX.Element {
        const searchIconProps = {
            field: {
                color: this.props.theme.color.normalFont,
            },
            icon: {
                color: this.props.theme.color.linkFont,
            },
        };
        const toggleStyleProps = {
            label: {
                color: this.props.theme.color.normalFont,
            },
            text: {
                color: this.props.theme.color.normalFont,
            },
        };
        const isClassicView = this.props.isClassicView;
        const isApplicationView = this.props.isApplicationView;

        return (
            <NavWrapper>
                <SpacedWrapper>
                    <Toggle
                        checked={isClassicView}
                        label="Environment"
                        onText="Classic"
                        offText="Stack"
                        onChange={this.onChangeClassicView}
                        styles={toggleStyleProps}
                    />
                    {(!isClassicView) && (
                        <Toggle
                            checked={isApplicationView}
                            label="View"
                            onText="Application"
                            offText="Cluster"
                            onChange={this.onChangeApplicationView}
                            styles={toggleStyleProps}
                        />
                    )}
                </SpacedWrapper>
                <PGeneratedDateTime>Generated: {isClassicView ? moment(`${this.props.latestCloudSummaryGeneratedDate}Z`).format("LLL") : moment(this.props.latestStampSummaryGeneratedDate).format("LLL")}</PGeneratedDateTime>
                <NavGroup
                    text="Filtering"
                    collapsed={this.state.isFilterColllapsed}
                    onClick={this.toggleFilterNavGroup}
                    Wrapper={FilterGroupWrapper}
                >
                    <NavSearchBox
                        placeholder="Filter verticals"
                        value={this.props.searchFilter}
                        onChange={this.props.setSearchFilter}
                        styles={searchIconProps}
                    />
                    <CenteredWrapper>
                        <Toggle
                            checked={this.props.showProductionOnlyFilter}
                            label="Production only"
                            onText="On"
                            offText="Off"
                            onChange={this.onChangeProductionOnlyFilter}
                            styles={toggleStyleProps}
                        />

                        <ToggleAllButton
                            text={`${(isClassicView && this.props.collapsedClouds.length > 0 || !isClassicView && this.props.collapsedStamps.length > 0)
                                ? "Expand" : "Collapse"} all`}
                            onClick={this.props.toggleAllCollapse}
                        />
                    </CenteredWrapper>
                </NavGroup>
                {(isClassicView && this.props.clouds == null || !isClassicView && this.props.stamps == null) ? <Spinner size={SpinnerSize.large} /> : this.getNavGroups()}
            </NavWrapper>
        );
    }

    private getCloudNavGroupItems = (cloud: ICloud): React.ReactNode => {
        const navGroupItems = cloud.verticals.map<React.ReactNode>((vertical) => {
            const build = this.getSelectedVerticalBuild(cloud.cloud, vertical.vertical);
            const isSelected = this.props.selectedCloud === cloud.cloud &&
                this.props.selectedVertical === vertical.vertical;
            let oldestDate = moment();
            vertical.applications.forEach((app) => {
                app.commits.forEach((commit) => {
                    const commitDate = moment(commit.created);
                    if (commitDate.isBefore(oldestDate) && commit.activation > 0) {
                        oldestDate = commitDate;
                    }
                });
            });
            const daysDiff = moment().diff(oldestDate, "days");
            return (
                <NavGroupItem
                    key={vertical.vertical}
                    text={vertical.vertical}
                    days={daysDiff}
                    selected={isSelected}
                    stackStatus={vertical.stack_status}
                    buildStatus={build ? build.status : null}
                    onClick={() => this.props.setSelectedCloudVertical(cloud.cloud, vertical.vertical)}
                />
            );
        });
        return (
            <NavGroup
                key={cloud.cloud}
                text={cloud.cloud}
                collapsed={this.props.collapsedClouds.indexOf(cloud.cloud) >= 0}
                onClick={() => this.props.toggleCollapse(cloud.cloud, null)}
            >
                {navGroupItems}
            </NavGroup>
        );
    }

    private getStampNavGroupItems = (stamp: IStamp): React.ReactNode => {
        const navGroupItems = stamp.Verticals.map<React.ReactNode>((vertical) => {
            const isSelected = this.props.selectedStamp === stamp.StampName &&
                this.props.selectedStackVertical === vertical.VerticalName;
            let oldestDate = moment();
            vertical.Clusters.forEach((cluster) => {
                cluster.Applications.forEach((app) => {
                    app.ReplicaSets.forEach((rs) => {
                        const commitDate = moment(rs.ReplicaSetCreationTime);
                        if (commitDate.isBefore(oldestDate) && cluster.EndpointWeight > 0) {
                            oldestDate = commitDate;
                        }
                    });
                });
            });
            const daysDiff = moment().diff(oldestDate, "days");
            return (
                <NavGroupItem
                    key={vertical.VerticalName}
                    text={vertical.VerticalName}
                    days={daysDiff}
                    selected={isSelected}
                    stackStatus={stamp.RolloutStatus}
                    buildStatus={null}
                    onClick={() => this.props.setSelectedStackVertical(stamp.StampName, vertical.VerticalName)}
                />
            );
        });
        return (
            <NavGroup
                key={stamp.StampName}
                text={stamp.StampName}
                collapsed={this.props.collapsedStamps.indexOf(stamp.StampName) >= 0}
                onClick={() => this.props.toggleCollapse(null, stamp.StampName)}
            >
                {navGroupItems}
            </NavGroup>
        );
    }

    private getNavGroups = (): React.ReactNode[] => {
        return this.props.isClassicView
            ? this.props.clouds.map<React.ReactNode>((cloud) => this.getCloudNavGroupItems(cloud))
            : this.props.stamps.map<React.ReactNode>((stamp) => this.getStampNavGroupItems(stamp));
    }

    private toggleFilterNavGroup = () => {
        this.setState({isFilterColllapsed: !this.state.isFilterColllapsed});
    }

    private onChangeProductionOnlyFilter = (_, checked) => {
        this.props.setProductionOnlyFilter(checked);
    }

    private onChangeClassicView = (_, checked) => {
        this.props.setClassicView(checked);
    }

    private onChangeApplicationView = (_, checked) => {
        this.props.setApplicationView(checked);
    }

    private getSelectedVerticalBuild = (cloud: string, vertical: string) => {
        const selectedCloud = this.props.buildStatusDictionary[cloud];
        if (!selectedCloud) {
            return null;
        }
        return selectedCloud[vertical];
    }
}

const mapStateToProps = (state): IStateProps => ({
    isClassicView: state.clouds.isClassicView,
    isApplicationView: state.clouds.isApplicationView,
    searchFilter: state.clouds.searchFilter,
    showProductionOnlyFilter: state.clouds.showProductionOnlyFilter,
    collapsedClouds: state.clouds.collapsedClouds,
    collapsedStamps: state.clouds.collapsedStamps,
    buildStatusDictionary: state.clouds.buildStatusDictionary,
    latestCloudSummaryGeneratedDate: state.clouds.latestCloudSummaryGeneratedDate,
    latestStampSummaryGeneratedDate: state.clouds.latestStampSummaryGeneratedDate,
});

const mapDispatchToProps = (dispatch): IActionProps => ({
    setClassicView: (checked: boolean) => { dispatch(setClassicView(checked)); },
    setApplicationView: (checked: boolean) => { dispatch(setApplicationView(checked)); },
    setSearchFilter: (_: React.ChangeEvent<HTMLInputElement>, query: string) => { dispatch(setSearchFilter(query)); },
    setProductionOnlyFilter: (checked: boolean) => { dispatch(setProductionOnlyFilter(checked)); },
    setSelectedCloudVertical: (cloud: string, vertical: string) => {
        dispatch(setSelectedCloudVertical(cloud, vertical));
    },
    setSelectedStackVertical: (stamp: string, vertical: string) => {
        dispatch(setSelectedStackVertical(stamp, vertical));
    },
    toggleCollapse: (cloud: string, stamp: string) => { dispatch(toggleCollapse(cloud, stamp)); },
    toggleAllCollapse: () => { dispatch(toggleAllCollapse()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(PrimaryNav)) as unknown as React.ComponentType<IPrimaryNavProps>;
