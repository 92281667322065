import { Checkbox, Dialog, Label } from "office-ui-fabric-react";
import {
    DefaultButton,
    PrimaryButton,
} from "office-ui-fabric-react/lib/Button";
import {
    CommandBar,
    ICommandBarItemProps,
} from "office-ui-fabric-react/lib/CommandBar";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import * as React from "react";
import { authProvider } from "../authProvider";
import { IStackVertical, IStamp, IStampRolloutResource } from "../containers/HomePage/reducer";
import {
    BuildA,
    BuildWrapper,
    ContentHeaderDiv,
    ContentHeaderTitle,
    ContentHeaderWrapper,
    ITheme,
    InlineBlockDiv,
    VerticalDetail,
    VerticalDetailsWrapper,
    withTheme,
} from "../utils/localStyledComponents";
import axios from "axios";
import { createCommandBarItem } from "./Shared/Helpers";
import { applicationNames } from "./Constants/Constants";
import { VerticalToolsUrl } from "../utils/VerticalTools";
import { DateTime, Is } from "../utils/Base";
import VerticalStatus from "./VerticalStatus";
import { Button } from "reactstrap";
interface IStackHeaderProps {
    theme: ITheme;
    vertical: IStackVertical;
    stamp: IStamp;
}

interface IState {
    addAppsDialogIsVisible: boolean;
    addAppsSelectedApps: string[];
    rolloutResourceDetailVisible: boolean;
    pfMainBranch?: string;
    runBuddyPipelineError?: string;
    buddyPipelineUrl?: string;
    isRequestProcessing: boolean;
}

export class StackHeader extends React.PureComponent<
    IStackHeaderProps,
    IState
> {
    constructor(props) {
        super(props);
        this.state = {
            addAppsDialogIsVisible: false,
            rolloutResourceDetailVisible: false,
            addAppsSelectedApps: [],
            pfMainBranch: "",
            runBuddyPipelineError: undefined,
            buddyPipelineUrl: undefined,
            isRequestProcessing: false,
        };
    }

    public render(): JSX.Element {
        if (!this.props.vertical) {
            return <Spinner size={SpinnerSize.large} />;
        }
        const EV2Pipeline = "0000000d-0000-8888-8000-000000000000@2c895908-04e0-4952-89fd-54b0046d6288" // hardcoded default RolloutOwner value for EV2 pipeline
        const verticalCommandBarStyles = {
            root: {
                backgroundColor: this.props.theme.color.background,
                borderBottom: `1px solid ${this.props.theme.color.border}`,
            },
        };

        return (
            <div>
                <ContentHeaderWrapper>
                    <div>
                        <ContentHeaderDiv>
                            <VerticalStatus isBig status={this.props.stamp.RolloutStatus || ""} />
                            <ContentHeaderTitle>{this.props.vertical.VerticalName}</ContentHeaderTitle>
                        </ContentHeaderDiv>
                    </div>
                    <VerticalDetailsWrapper>
                        <VerticalDetail>
                            <strong>EV2 Rollout: </strong>
                            <a href={`https://ra.ev2portal.azure.net/#/rollouts/Test/dd7f813e-b7b1-4225-82bd-783c5f0836f4/${this.props.stamp.RolloutServiceGroup}/${this.props.stamp.RolloutId}`} target="_blank" rel="noopener noreferrer">
                                {this.props.stamp.RolloutStatus}
                            </a>
                        </VerticalDetail>
                        <VerticalDetail>
                            <strong>Owner: </strong>
                            {this.props.stamp.RolloutOwner === EV2Pipeline ? "EV2 Pipeline" : this.props.stamp.RolloutOwner}
                        </VerticalDetail>
                        <VerticalDetail>
                            <strong>Created: </strong>
                            {DateTime.ToReadableDate(this.props.stamp.RolloutStartTime || "")}
                        </VerticalDetail>
                    </VerticalDetailsWrapper>
                </ContentHeaderWrapper>
                {this.getBuildContent()}
                <CommandBar
                    styles={verticalCommandBarStyles}
                    items={VerticalToolsUrl.isProductionVertical(this.props.stamp.StampName, this.props.vertical.VerticalName) ? [] : this.getVerticalCommandBarActions()}
                />
                <Dialog
                    dialogContentProps={{ title: "Add applications" }}
                    hidden={!this.state.addAppsDialogIsVisible}
                    onDismiss={this.hideAddApps}
                >
                    {!this.state.runBuddyPipelineError &&
                        !this.state.buddyPipelineUrl ? (
                        <>
                            <div className="checkbox-dialog-group">
                                {applicationNames.map((app) => {
                                    return (
                                        <Checkbox
                                            key={app}
                                            checked={this.state.addAppsSelectedApps.some(
                                                (s) => s === app
                                            )}
                                            onChange={this.onAddAppsCheckChange.bind(
                                                this,
                                                app
                                            )}
                                            label={app}
                                        />
                                    );
                                })}
                            </div>
                            <div className="branch-dialog-group">
                                <Label>(Optional) pf-main branch</Label>
                                <input
                                    type="text"
                                    value={this.state.pfMainBranch}
                                    onChange={(e) =>
                                        this.onBranchChange(e.target.value)
                                    }
                                    placeholder="master by default"
                                />
                            </div>
                            <div className="dialog-buttons">
                                <PrimaryButton
                                    onClick={this.addAppsConfirm}
                                    disabled={this.state.isRequestProcessing}
                                >
                                    Submit
                                </PrimaryButton>
                                <DefaultButton
                                    primary={false}
                                    onClick={this.hideAddApps}
                                    disabled={this.state.isRequestProcessing}
                                >
                                    Cancel
                                </DefaultButton>
                            </div>
                        </>
                    ) : (
                        <div className="content">
                            {this.state.buddyPipelineUrl && (
                                <a href={this.state.buddyPipelineUrl}>
                                    {this.state.buddyPipelineUrl}
                                </a>
                            )}
                            {this.state.runBuddyPipelineError && (
                                <div>{this.state.runBuddyPipelineError}</div>
                            )}
                            <div className="dialog-buttons">
                                <DefaultButton
                                    primary={false}
                                    onClick={this.hideAddApps}
                                >
                                    Close
                                </DefaultButton>
                            </div>
                        </div>
                    )}
                </Dialog>
            </div>
        );
    }

    private getBuildContent = () => {
        var rolloutResourceElements = this.props.stamp.RolloutResources.map((rolloutResource: IStampRolloutResource) => {
            return (
                <BuildWrapper status={rolloutResource.RolloutResourceState}>
                    <InlineBlockDiv>
                        <strong>EV2 Operation Name: </strong>
                        {rolloutResource.RolloutResourceName}
                    </InlineBlockDiv>
                    <InlineBlockDiv>
                        <strong>EV2 Operation Type: </strong>
                        {rolloutResource.RolloutResourceType}
                    </InlineBlockDiv>
                    {Is.Null(rolloutResource.BicepDeploymentName) ?
                        <InlineBlockDiv>
                            <strong>EV2 Operation Status: </strong>
                            {rolloutResource.RolloutResourceState}
                        </InlineBlockDiv> :
                        <InlineBlockDiv>
                            <strong>Bicep Deployment Status: </strong>
                            <BuildA href={`https://ms.portal.azure.com/#view/HubsExtension/DeploymentDetailsBlade/~/overview/id/%2Fsubscriptions%2F${this.props.stamp.StampSubscription}%2Fproviders%2FMicrosoft.Resources%2Fdeployments%2F${rolloutResource.BicepDeploymentName}`} target="_blank">
                                {rolloutResource.RolloutResourceState}
                            </BuildA>
                        </InlineBlockDiv>}
                    {rolloutResource.RolloutResourceStatusMessage &&
                        <InlineBlockDiv>
                            <PrimaryButton
                                onClick={this.showRolloutResourceDetail}
                            >
                                Details
                            </PrimaryButton>
                        </InlineBlockDiv>}
                    <Dialog
                        dialogContentProps={{ title: "Details" }}
                        hidden={!this.state.rolloutResourceDetailVisible}
                        onDismiss={this.hideRolloutResourceDetail}
                    >
                        {rolloutResource.RolloutResourceStatusMessage}
                    </Dialog>
                </BuildWrapper>
            );
        });
        return <div>{rolloutResourceElements}</div>
    }

    private onAddAppsCheckChange = (
        application: string,
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean
    ) => {
        if (checked) {
            this.setState({
                ...this.state,
                addAppsSelectedApps: this.state.addAppsSelectedApps.concat([
                    application,
                ]),
            });
        } else {
            this.setState({
                ...this.state,
                addAppsSelectedApps: this.state.addAppsSelectedApps.filter(
                    (a) => a !== application
                ),
            });
        }
    };

    private addAppsConfirm = async () => {
        this.setState({
            ...this.state,
            isRequestProcessing: true,
        });

        let pipelineUrl;
        let responseError;

        try {
            const token = await authProvider.getIdToken();
            const request = await axios({
                method: "post",
                url:
                    "https://" +
                    window.location.hostname +
                    ":" +
                    (window.location.port || "443") +
                    "/Data/RunBuddyPipeline",
                headers: {
                    Authorization: "Bearer " + token.idToken.rawIdToken,
                },
                data: {
                    Applications: this.state.addAppsSelectedApps,
                    PfMainBranch: this.state.pfMainBranch,
                },
            });
            pipelineUrl = this.getBuddyPipelineViewUrl(request.data.id);
            window.open(pipelineUrl);
        } catch (error) {
            console.error(error);
            responseError = JSON.stringify(error);
        }

        this.setState({
            ...this.state,
            addAppsDialogIsVisible: true,
            addAppsSelectedApps: [],
            pfMainBranch: "",
            runBuddyPipelineError: responseError,
            buddyPipelineUrl: pipelineUrl,
            isRequestProcessing: false,
        });
    };

    private showAddApps = () => {
        this.setState({
            ...this.state,
            addAppsDialogIsVisible: true,
            addAppsSelectedApps: [],
        });
    };

    private hideAddApps = () => {
        this.setState({
            ...this.state,
            addAppsDialogIsVisible: false,
            addAppsSelectedApps: [],
            pfMainBranch: "",
            runBuddyPipelineError: undefined,
            buddyPipelineUrl: undefined,
        });
    };

    private showRolloutResourceDetail = () => {
        this.setState({
            ...this.state,
            rolloutResourceDetailVisible: true
        });
    };

    private hideRolloutResourceDetail = () => {
        this.setState({
            ...this.state,
            rolloutResourceDetailVisible: false
        });
    };

    private onBranchChange = (branchName) => {
        this.setState({
            ...this.state,
            pfMainBranch: branchName,
        });
    };

    private getVerticalCommandBarActions = () => {
        const appActionCommandBarItems: ICommandBarItemProps[] = [
            createCommandBarItem(
                "Apps",
                undefined,
                "App: ",
                undefined,
                undefined,
                undefined,
                this.props.theme.color
            ),
            createCommandBarItem(
                "add",
                "Add applications",
                undefined,
                "Add",
                this.showAddApps,
                undefined,
                this.props.theme.color
            ),
        ];
        return appActionCommandBarItems;
    };

    private getBuddyPipelineViewUrl = (buildId) => {
        return `https://microsoft.visualstudio.com/PlayFab/_build/results?buildId=${buildId}&view=results`;
    };
}

export default withTheme(StackHeader);
