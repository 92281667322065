// tslint:disable:rule max-classes-per-file
import * as moment from "moment";

export interface IReactEnumable {
    jsxkey?: string;
}

export interface IStringDictionary {
    [key: string]: string;
}

export class ID {
    public static current = 0;

    public static next(): string {
        return (this.current++).toString();
    }
}

export class String {
    public static Format(original: string, ...args: any[]): string {
        if (Is.Null(original) || Is.Null(args)) {
            return "";
        }

        if (Is.Array(args[0])) {
            args = args[0];
        }

        args.forEach((arg: string, i: number) => {
            original = original.replace(new RegExp("\\{" + i + "\\}", "gim"), args[i]);
        });

        return original;
    }
}

export class Is {
    public static Null(data: any): boolean {
        return typeof data === "undefined"
            || data === null
            || (typeof data === "string" && data.length === 0)
            || (Is.Array(data) && data.length === 0);
    }

    public static Array(data: any): boolean {
        if (data === null || typeof data === "undefined") {
            return false;
        }

        return data.constructor === Array;
    }

    public static Date(data: any): boolean {
        return data instanceof DateTime;
    }

    public static DateString(data: any): boolean {
        return !Is.Null(data) && data.indexOf("/Date(") === 0;
    }

    public static Numeric(data: any): boolean {
        return typeof data !== "undefined"
            && data !== null
            && (data.toString().length > 0
            // eslint-disable-next-line
            && data.toString().replace(/[\d\.]+/gi, "").length === 0
            && !isNaN(data));
    }

    public static String(data: any): boolean {
        return !Is.Null(data) && typeof data === "string";
    }

    public static ValidHttpUrl(data: any): boolean {
        // Copyright(c) 2010- 2013 Diego Perini (http://www.iport.it)
        //
        // Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated
        // documentation files (the "Software"), to deal in the Software without restriction, including without
        // limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and / or sell copies of
        // the Software, and to permit persons to whom the Software is furnished to do so, subject to the following
        // conditions:
        //
        // The above copyright notice and this permission notice shall be included in all copies or substantial portions
        // of the Software.
        //
        // THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
        // TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL
        // THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF
        // CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER
        // DEALINGS IN THE SOFTWARE.
        // https://gist.github.com/dperini/729294
        // see also https://mathiasbynens.be/demo/url-regex
        //
        // Modified to allow protocol-relative URLs
        // and modified again to remove ftp (Jordan Roher)

        if (Is.Null(data)) {
            return false;
        }
        // tslint:disable-next-line:rule max-line-length
        return /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(data);
    }
}

export class DateTime {
    public static ToReadableDate(date: string | Date): string {
        if (Is.Null(date)) {
            return "";
        }

        const localDate: moment.Moment = moment.utc(date).local();
        const now: moment.Moment = moment.utc().local();

        if (localDate.year() !== now.year()) {
            return localDate.format("MMMM Do, YYYY h:mm A");
        } else if (localDate.dayOfYear() !== now.dayOfYear()) {
            return localDate.format("MMMM Do h:mm A");
        } else {
            return localDate.format("h:mm A");
        }
    }

    public static ToShortDate(date: string | Date): string {
        if (Is.Null(date)) {
            return "";
        }

        const localDate: moment.Moment = moment.utc(date).local();
        const now: moment.Moment = moment.utc().local();

        if (localDate.year() !== now.year()) {
            return localDate.format("M/D/Y h:mm A");
        } else if (localDate.dayOfYear() !== now.dayOfYear()) {
            return localDate.format("M/D h:mm A");
        } else {
            return localDate.format("h:mm A");
        }
    }
}
