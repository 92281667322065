import * as React from "react";

import { Status } from "../utils/VerticalTools";

export interface ICFNStackLinkProps {
    stackId: string;
    stackStatus: string;
}

export default class CFNStackLink extends React.PureComponent<ICFNStackLinkProps> {
    public render(): JSX.Element {
        const awsLink: string = "https://us-west-2.console.aws.amazon.com";
        const cloudFormationDetails: string = "/cloudformation/home?region=us-west-2#/stack/detail";
        const link: string = `${awsLink}${cloudFormationDetails}?stackId=${encodeURIComponent(this.props.stackId)}`;

        return (
            <a href={link} target="_blank" rel="noopener noreferrer">{Status.ToName(this.props.stackStatus)}</a>
        );
    }
}
