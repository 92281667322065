import * as styledComponents from "styled-components";
import { ThemedStyledComponentsModule } from "styled-components";

export interface ITheme {
    color: IThemeColor;
    size: IThemeSize;
    device: IThemeDevice;
    font: IThemeFont;
}

export interface IThemeColor {
    playfabOrange: string;
    success: string;
    warning: string;
    failed: string;
    headerBackground: string;
    cardBackground: string;
    primaryNavBackground: string;
    primaryNavHighlight: string;
    background: string;
    border: string;
    cardBorder: string;
    secondaryBorder: string;
    headerFont: string;
    normalFont: string;
    linkFont: string;
    secondaryLinkFont: string;
    buildStatusLinkFont: string;
}

export interface IThemeSize {
    navHeight: string;
    primaryNavWidth: string;
}

export interface IThemeDevice {
    tiny: string;
    small: string;
    medium: string;
    large: string;
    huge: string;
}

export interface IThemeFont {
    big1: string;
    big1Light: string;
    big2: string;
    normal: string;
    normalBold: string;
    small2Semibold: string;
}

const defaultTheme: ITheme = {
    color: {
        playfabOrange: "#ff6d21",
        success: "#21ba45",
        warning: "#ffcc00",
        failed: "#db2828",
        headerBackground: "#1C2932",
        cardBackground: "#fff",
        primaryNavBackground: "#f9f9f9",
        primaryNavHighlight: "#ededed",
        background: "#fff",
        border: "rgba(0, 0, 0, .08)",
        cardBorder: "rgba(0, 0, 0, .2)",
        secondaryBorder: "#aaa",
        headerFont: "#fff",
        normalFont: "#333333",
        linkFont: "#2497FF",
        secondaryLinkFont: "#7ac0ff",
        buildStatusLinkFont: "#0075DE",
    },
    size: {
        navHeight: "60px",
        primaryNavWidth: "17.5rem",
    },
    device: {
        tiny: "(min-width: 320px)",
        small: "(min-width: 480px)",
        medium: "(min-width: 768px)",
        large: "(min-width: 1024px)",
        huge: "(min-width: 1280px)",
    },
    font: {
        big1: "font: 1.333em/1.325 \"Open Sans\", Helvetica, Arial, sans-serif;",
        big1Light: "font: 1.333em/1.325 \"Open Sans Light\", Helvetica, Arial, sans-serif;",
        big2: "font: 1.777em/1.3 \"Open Sans Light\", Helvetica, Arial, sans-serif;",
        normal: "font: 1em/1.375 \"Open Sans\", Helvetica, Arial, sans-serif;",
        normalBold: "font: 1em/1.375 \"Open Sans Bold\", Helvetica, Arial, sans-serif;",
        small2Semibold: "font: 0.75em/1.5 \"Open Sans Semibold\", Helvetica, Arial, sans-serif;",
    },
};

const darkTheme: ITheme = {
    ...defaultTheme,
    color: {
        playfabOrange: "#ff6d21",
        success: "#21ba45",
        warning: "#DAAE00",
        failed: "#db2828",
        headerBackground: "#212121",
        cardBackground: "#212121",
        primaryNavBackground: "#000",
        primaryNavHighlight: "#101010",
        background: "#131313",
        border: "#4A4A4A",
        cardBorder: "#4A4A4A",
        secondaryBorder: "#4A4A4A",
        headerFont: "#fff",
        normalFont: "#fff",
        linkFont: "#7ac0ff",
        secondaryLinkFont: "#7ac0ff",
        buildStatusLinkFont: "#0075DE",
    },
};

const {
    default: styled,
    css,
    createGlobalStyle,
    keyframes,
    ThemeProvider,
    withTheme,
} = styledComponents as ThemedStyledComponentsModule<ITheme>;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const Content = styled.div`
    display: flex;
    flex: 1;
`;

const PageContent = styled.div`
    background-color: ${(props) => props.theme.color.background}
    flex-grow: 1;
    flex-shrink: 1;
`;

const AppContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const CardWrapper = styled.div`
    padding: 1rem;
`;

const ContentHeaderTitle = styled.h1`
    color: ${(props) => props.theme.color.normalFont}
    display: inline;
`;

const ContentHeaderDiv = styled.div`
    display: flex;
    align-items: center;
`;

const ContentHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 1.5rem;
    color: ${(props) => props.theme.color.normalFont}

    @media screen and (max-width: 479px) {
        flex-wrap: wrap;
    }
`;

const VerticalDetailsWrapper = styled.div`
    color: ${(props) => props.theme.color.normalFont}
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
`;

const VerticalDetail = styled.div`
    margin: 0 .5rem;
`;

const BuildWrapper = styled.div<{status: string}>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: .5rem 1.25rem;
    padding: .25rem;
    background-color: ${(props) => props.status === "error" || props.status === "Failed" ? "#d4a9a9" : "#aaa"};
`;

const InlineBlockDiv = styled.div`
    display: inline-block;
`;

const BuildA = styled.a`
    color: ${(props) => props.theme.color.buildStatusLinkFont};
`;

export { css, createGlobalStyle, keyframes, ThemeProvider, defaultTheme, darkTheme, withTheme, 
    Wrapper, Content, PageContent, AppContainer, CardWrapper, ContentHeaderDiv, ContentHeaderTitle, ContentHeaderWrapper, 
    VerticalDetailsWrapper, VerticalDetail, BuildWrapper, InlineBlockDiv, BuildA };
export default styled;
