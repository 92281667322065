import { Icon, IconButton } from "office-ui-fabric-react";
import * as React from "react";

import { DateTime, Is, IReactEnumable } from "../../utils/Base";
import styled from "../../utils/localStyledComponents";
import { VerticalToolsUrl } from "../../utils/VerticalTools";
import { IReplicaSetProps, StackApplicationReplicaSet } from "./StackApplicationReplicaSet";
import { IPipelineRun } from "../../containers/HomePage/reducer";

const ApplicationCommitIcon = styled(Icon)`
    font-size: 0.75rem;
    padding: 0 .25rem;
`;

export interface IApplicationProps extends IReactEnumable {
    ApplicationName: string;
    StampName: string;
    VerticalName: string;
    StampSubscription: string;
    ClusterName: string;
    DeploymentCreationTime: string;
    EndpointWeight: number;
    ReplicaSets: IReplicaSetProps[];
    buddyPipelineRuns: IPipelineRun[];
    officialPipelineRuns: IPipelineRun[];
}

export class StackClusterApplication extends React.PureComponent<IApplicationProps> {
    public render() {
        const headerLink = VerticalToolsUrl.applicationRoute(this.props.StampName, this.props.ApplicationName + "_stack", VerticalToolsUrl.getCloudName(this.props.StampName));
        const header = Is.Null(headerLink)
            ? this.props.ApplicationName
            : (<a href={headerLink} target="_blank" rel="noopener noreferrer">{this.props.ApplicationName}</a>);
        const stackCloudName = VerticalToolsUrl.getCloudName(this.props.StampName)
        const stackApplicationName = this.props.ApplicationName + "_stack";

        let replicaSetElements: JSX.Element = (<div />);
        if (!Is.Null(this.props.ReplicaSets)) {
            replicaSetElements = (
                <div>
                    {this.props.ReplicaSets.map((rs: IReplicaSetProps) => {
                        return (
                            <StackApplicationReplicaSet
                                key={rs.jsxkey}
                                ApplicationName={rs.ApplicationName}
                                StampName={rs.StampName}
                                Namespace={rs.Namespace}
                                StampSubscription={rs.StampSubscription}
                                ClusterName={rs.ClusterName}
                                EndpointWeight={rs.EndpointWeight}
                                Commit={rs.Commit}
                                ReplicaSetCreationTime={rs.ReplicaSetCreationTime}
                                PodStatus={rs.PodStatus}
                                ReplicaSetName={rs.ReplicaSetName}
                                BuildVersion={rs.BuildVersion}
                                buddyPipelineRuns={rs.buddyPipelineRuns}
                                officialPipelineRuns={rs.officialPipelineRuns}
                            />
                        );
                    })}
                </div>
            );
        }

        return (
            <div className="ui segment stamp-cluster-application">
                <h4 className="ui header">
                <div className="commit-helpers">
                        {VerticalToolsUrl.mdmLogsExist(stackApplicationName)
                            ? (
                                <IconButton
                                    title="Geneva Logs"
                                    iconProps={{ iconName: "TextDocument" }}
                                    target="_blank"
                                    href={VerticalToolsUrl.mdmLogsForApplicationCommit(this.props.VerticalName, stackApplicationName, "", stackCloudName)}
                                />
                            )
                            : null}
                        {VerticalToolsUrl.kustoLogsExist(stackApplicationName)
                            ? (
                                <IconButton
                                    title="Kusto Logs"
                                    iconProps={{ iconName: "QueryList" }}
                                    target="_blank"
                                    href={VerticalToolsUrl.kustoLogsForApplicationCommit(this.props.VerticalName, stackApplicationName, "", stackCloudName)}
                                />
                            )
                            : null}
                        {VerticalToolsUrl.mdmDashExist(stackApplicationName)
                            ? (
                                <IconButton
                                    title="Geneva Dashboard"
                                    iconProps={{ iconName: "LineChart" }}
                                    target="_blank"
                                    href={VerticalToolsUrl.mdmDashForApplicationCommit(this.props.VerticalName, stackApplicationName, "", stackCloudName)}
                                />
                            )
                            : null}
                    </div>
                    <div className="content">
                        {header}
                    </div>
                </h4>

                <table>
                    <tbody>
                        <tr>
                            <th>Deployment Created</th>
                            <td>{DateTime.ToShortDate(this.props.DeploymentCreationTime)}</td>
                        </tr>
                    </tbody>
                </table>
                {replicaSetElements}
            </div>
        );
    }
}
