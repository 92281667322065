export const applicationNames: string[] = [
    "cloudscriptint",
    "gamemanager",
    "insightsproxy",
    "legacymatchmaker",
    "mainserver",
    "playerportal",
    "playstream_actionprocessor",
    "playstream_analyticsprocessor",
    "playstream_entityprofileeventprocessor",    
    "playstream_entityactionprocessor",
    "playstream_eventarchiver",
    "playstream_headdispatcher",
    "playstream_indexprocessor",
    "playstream_livesite",
    "playstream_playstreammonitor",
    "playstream_segmentprocessor",
    "playstream_statsprocessor",
    "playstream_webhooksprocessor",
    "queueprocessor",
    "taskprocessor",
    "traefik"    
];
