import { Dialog, Icon, IconButton } from "office-ui-fabric-react";
import * as React from "react";

import { Is, IReactEnumable } from "../../utils/Base";
import styled from "../../utils/localStyledComponents";
import { VerticalToolsUrl } from "../../utils/VerticalTools";
import { IReplicaSetProps, StackApplicationReplicaSet } from "./StackApplicationReplicaSet";
import { IPipelineRun } from "../../containers/HomePage/reducer";

const ApplicationCommitIcon = styled(Icon)`
    font-size: 0.75rem;
    padding: 0 .25rem;
`;

interface IState {
    isDialogOpen: boolean;
}

export interface IClusterProps extends IReactEnumable {
    DeploymentName: string;
    ApplicationName: string;
    Namespace: string;
    StampName: string;
    StampSubscription: string;
    ClusterName: string;
    EndpointWeight: number;
    ReplicaSets: IReplicaSetProps[];
    buddyPipelineRuns: IPipelineRun[];
    officialPipelineRuns: IPipelineRun[];
}

export class StackApplicationCluster extends React.PureComponent<IClusterProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            isDialogOpen: false,
        };
    }

    public render() {
        const managedClusterRoute = VerticalToolsUrl.managedClusterRoute(
            this.props.StampSubscription, this.props.StampName, this.props.ClusterName);
        const stackCloudName = VerticalToolsUrl.getCloudName(this.props.StampName)
        const setClusterSubscriptionCommand = `az account set --subscription ${this.props.StampSubscription}`
        const downloadClusterCredentialsCommand = `az aks get-credentials --resource-group ${this.props.StampName}-01-shared --name ${this.props.ClusterName}`

        let replicaSetElements: JSX.Element = (<div />);
        if (!Is.Null(this.props.ReplicaSets)) {
            replicaSetElements = (
                <div>
                    {this.props.ReplicaSets.map((rs: IReplicaSetProps) => {
                        return (
                            <StackApplicationReplicaSet
                                key={rs.jsxkey}
                                ApplicationName={rs.ApplicationName}
                                Namespace={rs.Namespace}
                                StampName={rs.StampName}
                                StampSubscription={rs.StampSubscription}
                                ClusterName={rs.ClusterName}
                                EndpointWeight={rs.EndpointWeight}
                                ReplicaSetName={rs.ReplicaSetName}
                                Commit={rs.Commit}
                                ReplicaSetCreationTime={rs.ReplicaSetCreationTime}
                                PodStatus={rs.PodStatus}
                                BuildVersion={rs.BuildVersion}
                                buddyPipelineRuns={rs.buddyPipelineRuns}
                                officialPipelineRuns={rs.officialPipelineRuns}
                            />
                        );
                    })}
                </div>
            );
        }

        return (
            <div className="ui segment stamp-application-cluster">
                <Dialog
                    dialogContentProps={{ title: "Connect to " + this.props.ClusterName }}
                    hidden={!this.state.isDialogOpen}
                    onDismiss={this.hideCommands}
                >
                    <h4>Set the cluster subscription</h4>
                    <div>{setClusterSubscriptionCommand}</div>
                    <div>
                        <IconButton
                            title="Copy to Clipboard"
                            iconProps={{ iconName: "Copy" }}
                            onClick={() => navigator.clipboard.writeText(setClusterSubscriptionCommand)}
                        />
                    </div>
                    <h4>Download cluster credentials</h4>
                    <div>{downloadClusterCredentialsCommand}</div>
                    <div>
                        <IconButton
                            title="Copy to Clipboard"
                            iconProps={{ iconName: "Copy" }}
                            onClick={() => navigator.clipboard.writeText(downloadClusterCredentialsCommand)}
                        />
                    </div>
                </Dialog>
                <h4 className="ui header">
                    <div className="commit-helpers">
                        <IconButton
                            title="Connect to Cluster"
                            iconProps={{ iconName: "PlugConnected" }}
                            onClick={() => this.showCommands()}
                        />
                        <IconButton
                            title="Geneva Dashboard"
                            iconProps={{ iconName: "LineChart" }}
                            target="_blank"
                            href={VerticalToolsUrl.mdmDashForApplicationCluster(this.props.DeploymentName, this.props.Namespace, stackCloudName, this.props.ClusterName)}
                        />
                    </div>
                    <div className="content">
                        {Is.Null(managedClusterRoute)
                            ? this.props.ClusterName
                            : (<a href={managedClusterRoute} target="_blank" rel="noopener noreferrer">{this.props.ClusterName}</a>)}
                    </div>
                </h4>
                {replicaSetElements}
            </div>
        );
    }

    private showCommands = () => {
        this.setState({
            ...this.state,
            isDialogOpen: true,
        });
    }

    private hideCommands = () => {
        this.setState({
            ...this.state,
            isDialogOpen: false,
        });
    }
}
