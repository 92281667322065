import {
    SET_PRODUCTION_ONLY_FILTER, SET_SEARCH_FILTER, SET_SELECTED_CLOUD_VERTICAL, SET_APPLICATION_VIEW,
    TOGGLE_ALL_COLLAPSE, TOGGLE_COLLAPSE, SET_CLASSIC_VIEW, SET_SELECTED_STAMP_VERTICAL,
} from "./constants";

export function setSelectedCloudVertical(cloud, vertical) {
    return {
        type: SET_SELECTED_CLOUD_VERTICAL,
        cloud,
        vertical,
    };
}

export function setSelectedStackVertical(stamp, vertical) {
    return {
        type: SET_SELECTED_STAMP_VERTICAL,
        stamp,
        vertical,
    };
}

export function setProductionOnlyFilter(checked) {
    return {
        type: SET_PRODUCTION_ONLY_FILTER,
        checked,
    };
}

export function setClassicView(checked) {
    return {
        type: SET_CLASSIC_VIEW,
        checked,
    };
}

export function setApplicationView(checked) {
    return {
        type: SET_APPLICATION_VIEW,
        checked,
    };
}

export function setSearchFilter(query) {
    return {
        type: SET_SEARCH_FILTER,
        query,
    };
}

export function toggleCollapse(cloud, stamp) {
    return {
        type: TOGGLE_COLLAPSE,
        cloud,
        stamp,
    };
}

export function toggleAllCollapse() {
    return {
        type: TOGGLE_ALL_COLLAPSE,
    };
}
