import { ActionButton, Icon, IconButton } from "office-ui-fabric-react";
import * as React from "react";

import VerticalStatus from "../VerticalStatus";
import { DateTime, Is, IReactEnumable } from "../../utils/Base";
import styled from "../../utils/localStyledComponents";
import { Status, VerticalToolsJenkins, VerticalToolsUrl  } from "../../utils/VerticalTools";

const ApplicationCommitIcon = styled(Icon)`
    font-size: 0.75rem;
    padding: 0 .25rem;
`;

export interface IVerticalApplicationCommitProps extends IReactEnumable {
    vertical: string;
    cloud: string;
    application: string;

    activation: number;
    commit: string;
    created: string;
    owner: string;
    elb?: {
        instances_inservice: number;
        instances_total: number;
        name: string;
    };
    region: string;
    running_instance_count: number;
    stack: string;
    stack_id: string;
    stack_status: string;
    commits_ahead_master: string;
    commits_behind_master: string;
}

export class VerticalApplicationCommit extends React.PureComponent<IVerticalApplicationCommitProps> {
    public render() {
        const commitRoute = VerticalToolsUrl.applicationCommitRoute(
            this.props.vertical, this.props.cloud, this.props.application, this.props.commit);
        const canActivate = this.props.activation < 100;
        const canTerminate = !this.isUpdating() && this.props.activation === 0;
        const canSeeIntegrationTest = this.props.application === "mainserver";

        const canActivateButton = canActivate
        ? (
            <ActionButton iconProps={{iconName: "Rocket"}} onClick={this.onClickActiveButton}>
                Activate
            </ActionButton>
        )
        : null;
        const canTerminateButton = canTerminate
        ? (
            <ActionButton iconProps={{iconName: "Delete"}} onClick={this.onClickTerminateButton}>
                Delete
            </ActionButton>
        )
        : null;

        return (
            <div className="ui segment vertical-application-commit">
                <h4 className="ui header">
                    <VerticalStatus status={this.props.stack_status} />
                    <div className="content">
                        <div className="commit-helpers">
                            {VerticalToolsUrl.mdmLogsExist(this.props.application)
                                ? (
                                    <IconButton
                                        title="Geneva Logs"
                                        iconProps={{iconName: "TextDocument"}}
                                        target="_blank"
                                        href={VerticalToolsUrl.mdmLogsForApplicationCommit(this.props.vertical, this.props.application, this.props.commit, this.props.cloud)}
                                    />
                                )
                                : null}
                            {VerticalToolsUrl.kustoLogsExist(this.props.application)
                                ? (
                                    <IconButton
                                        title="Kusto Logs"
                                        iconProps={{iconName: "QueryList"}}
                                        target="_blank"
                                        href={VerticalToolsUrl.kustoLogsForApplicationCommit(this.props.vertical, this.props.application, this.props.commit, this.props.cloud)}
                                    />
                                )
                                : null}
                            {VerticalToolsUrl.mdmDashExist(this.props.application)
                                ? (
                                    <IconButton
                                        title="Geneva Dashboard"
                                        iconProps={{iconName: "LineChart"}}
                                        target="_blank"
                                        href={VerticalToolsUrl.mdmDashForApplicationCommit(this.props.vertical, this.props.application, this.props.commit, this.props.cloud)}
                                    />
                                )
                                : null}
                            {canSeeIntegrationTest
                                ? (
                                    <IconButton
                                        title="Integration test"
                                        iconProps={{iconName: "TestBeakerSolid"}}
                                        target="_blank"
                                        href={VerticalToolsUrl.verticalIntegrationTestForApplicationCommit(this.props.vertical, this.props.commit)}
                                    />
                                )
                                : null}
                        </div>
                        {Is.Null(commitRoute)
                            ? this.props.commit
                            : (<a href={commitRoute} target="_blank" rel="noopener noreferrer">{this.props.commit}</a>)}
                    </div>
                </h4>

                <table>
                    <tbody>
                        <tr>
                            <th>Stack</th>
                            <td>
                                <a
                                    href={VerticalToolsUrl.cloudFormationStack(this.props.region, this.props.stack_id)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {Status.ToName(this.props.stack_status)}
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <th>Created</th>
                            <td>{DateTime.ToShortDate(this.props.created)}</td>
                        </tr>
                        <tr>
                            <th>Owner</th>
                            <td>
                                {this.props.owner}
                            </td>
                        </tr>
                        <tr>
                            <th>Instances</th>
                            <td>
                                <a
                                    href={VerticalToolsUrl.ec2Instances(this.props.region, this.props.stack_id)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {this.props.running_instance_count}
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <th>Commit</th>
                            <td>
                                <a href={VerticalToolsUrl.githubCommit(this.props.commit)} target="_blank" rel="noopener noreferrer">
                                    {this.props.commit}
                                </a>
                                {!Is.Null(this.props.commits_ahead_master) && !Is.Null(this.props.commits_behind_master)
                                    ? (
                                        <div className="commits">
                                            <span data-toggle="tooltip" title="Commits behind master">
                                                <ApplicationCommitIcon iconName="Back" />
                                                {this.props.commits_behind_master}
                                            </span>
                                            <span data-toggle="tooltip" title="Commits ahead of master">
                                                <span className="right floated">
                                                    {this.props.commits_ahead_master}
                                                    <ApplicationCommitIcon iconName="Forward" />
                                                </span>
                                            </span>
                                        </div>
                                    )
                                    : null}
                            </td>
                        </tr>
                        <tr>
                            <th className={(Is.Null(this.props.elb) ? "hidden" : "")}>ELB</th>
                            <td>
                                {Is.Null(this.props.elb)
                                    ? null
                                    : (
                                        <a href={VerticalToolsUrl.elb(this.props.region, this.props.elb!.name)} target="_blank" rel="noopener noreferrer">
                                            {this.props.elb!.instances_inservice} / {this.props.elb!.instances_total} in service
                                        </a>
                                    )
                                }
                            </td>
                        </tr>
                        <tr>
                            <th className={(Is.Null(this.props.elb) ? "hidden" : "")}>ASG</th>
                            <td>
                                <a href={VerticalToolsUrl.asg(this.props.region, this.props.vertical, this.props.application, this.props.commit)} target="_blank" rel="noopener noreferrer">
                                    Link to ASG
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="ui content list">
                    <div className="ui mini statistic active-percentage">
                        <div className="value">{this.props.activation}%</div>
                        <div className="label">Active</div>
                    </div>
                    <div className="content commit-actions">
                        {canActivateButton}
                        {canTerminateButton}
                    </div>
                </div>
            </div>
        );
    }

    private isUpdating = (): boolean => {
        return this.props.stack_status.indexOf("IN_PROGRESS") > -1;
    }

    private getUrlData = (): Array<[string, string]> => {
        return [
            ["Vertical", this.props.vertical],
            ["Application", this.props.application],
            ["Commit", this.props.commit],
        ];
    }

    private onClickActiveButton = (): void => {
        const url = VerticalToolsJenkins.JobStartFormUrl("activate-application", this.getUrlData());

        window.open(url);
    }

    private onClickTerminateButton = (): void => {
        const url = VerticalToolsJenkins.JobStartFormUrl("remove-application", this.getUrlData());

        window.open(url);
    }
}
