
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import * as React from "react";
import { connect } from "react-redux";

import { loadVerticalBuildingStatus, loadVerticals, loadStamps, loadBuddyPipelineRuns, loadOfficialPipelineRuns } from "./actions";
import { ICloud, ICloudBuildStatusDictionary, IStamp, IPipelineRun } from "./reducer";
import Header from "../Header/Header";
import PrimaryNav from "../PrimaryNav/PrimaryNav";
import VerticalContent from "../../components/VerticalContent";
import { Is } from "../../utils/Base";
import { VerticalToolsUrl } from "../../utils/VerticalTools";
import StampContent from "../../components/StackContent";
import { Wrapper, Content, PageContent } from "../../utils/localStyledComponents";

interface IHomePageReduxProps {
    isClassicView: boolean;
    isApplicationView: boolean;
    selectedStamp: string;
    selectedStackVertical: string;
    stamps: IStamp[];
    selectedCloud: string;
    selectedVertical: string;
    clouds: ICloud[];
    buddyPipelineRuns: IPipelineRun[];
    officialPipelineRuns: IPipelineRun[];
    autoRefresh: boolean;
    searchFilter: string;
    showProductionOnlyFilter: boolean;
    buildStatusDictionary: ICloudBuildStatusDictionary;
    loadVerticals: () => void;
    loadVerticalBuildingStatus: (cloud: string, vertical: string) => void;
    loadStamps: () => void;
    loadBuddyPipelineRuns: () => void;
    loadOfficialPipelineRuns: () => void;
}

interface IHomePageState {
    vertRefreshInterval: number | null;
    buildRefreshInterval: number | null;
}

export class HomePage extends React.PureComponent<IHomePageReduxProps, IHomePageState> {
    constructor(props) {
        super(props);
        this.state = {
            vertRefreshInterval: null,
            buildRefreshInterval: null,
        };
    }

    public componentDidMount() {
        this.props.loadVerticals();
        this.props.loadStamps();

        this.props.loadBuddyPipelineRuns();
        this.props.loadOfficialPipelineRuns();
        if (this.props.autoRefresh) {
            this.start();
        } else {
            this.stop();
        }
    }

    public componentDidUpdate(prevProps) {
        if (prevProps.autoRefresh !== this.props.autoRefresh) {
            if (this.props.autoRefresh) {
                this.props.loadVerticals();
                this.props.loadStamps();
                this.props.loadBuddyPipelineRuns();
                this.props.loadOfficialPipelineRuns();
                this.loadAllVerticalBuildingStatus();
                this.start();
            } else {
                this.stop();
            }
        }
    }

    public componentWillUnmount() {
        this.stop();
    }

    public render(): JSX.Element {
        const vertical = this.getSelectedVertical();
        const stackVertical = this.getSelectedStackVertical();
        const stamp = this.getSelectedStamp();
        const isClassicView = this.props.isClassicView;
        return (
            <Wrapper>
                <Header />
                <Content>
                    <PrimaryNav
                        clouds={this.getFilteredCloudVerticals()}
                        stamps={this.getFilteredStackVerticals()}
                        selectedCloud={this.props.selectedCloud}
                        selectedVertical={this.props.selectedVertical}
                        selectedStamp={this.props.selectedStamp}
                        selectedStackVertical={this.props.selectedStackVertical}
                    />
                    <PageContent>
                        {isClassicView && Is.Null(vertical) || !isClassicView && Is.Null(stackVertical)
                            ? (<Spinner size={SpinnerSize.large} />)
                            : isClassicView ? (
                                <VerticalContent
                                    vertical={vertical!}
                                    verticalBuild={this.getSelectedVerticalBuild() || undefined}
                                />
                            ) : <StampContent
                                    vertical={stackVertical!}
                                    stamp={stamp!}
                                    buddyPipelineRuns={this.props.buddyPipelineRuns}
                                    officialPipelineRuns={this.props.officialPipelineRuns} />}
                    </PageContent>
                </Content>
            </Wrapper>
        );
    }

    private start = () => {
        if (this.state.buildRefreshInterval !== null) {
            return;
        }
        const vertRefreshInterval = window.setInterval(() => {
            this.props.loadVerticals();
            this.props.loadStamps();
            this.props.loadBuddyPipelineRuns();
            this.props.loadOfficialPipelineRuns();
        }, 5000);

        const buildRefreshInterval = window.setInterval(() => {
            this.loadAllVerticalBuildingStatus();
        }, 15000);

        this.setState({
            vertRefreshInterval,
            buildRefreshInterval,
        });
    }

    private loadAllVerticalBuildingStatus = () => {
        if (this.props.clouds) {
            this.props.clouds.forEach((cloud) => {
                cloud.verticals.forEach((vertical) => {
                    this.props.loadVerticalBuildingStatus(cloud.cloud, vertical.vertical);
                });
            });
        }
    }

    private stop = (): void => {
        window.clearInterval(this.state.vertRefreshInterval);
        window.clearInterval(this.state.buildRefreshInterval);

        this.setState({
            vertRefreshInterval: 0,
            buildRefreshInterval: 0,
        });
    }

    private getFilteredCloudVerticals = (): ICloud[] => {
        const result = this.props.clouds.map((cloud) => Object.assign({}, cloud));

        result.forEach((cloud) => {
            cloud.verticals = cloud.verticals.filter(
                (vertical) => (vertical.vertical.includes(this.props.searchFilter) && (
                    !this.props.showProductionOnlyFilter ||
                    VerticalToolsUrl.isProductionVertical(vertical.cloud, vertical.vertical))),
            );
        });

        return result.filter((cloud) => cloud.verticals.length > 0);
    }

    private getFilteredStackVerticals = (): IStamp[] => {
        const result = this.props.stamps.map((stamp) => Object.assign({}, stamp));

        result.forEach((stamp) => {
            stamp.Verticals = stamp.Verticals.filter(
                (vertical) => (vertical.VerticalName.includes(this.props.searchFilter) && (
                    !this.props.showProductionOnlyFilter ||
                    VerticalToolsUrl.isProductionVertical(stamp.StampName, vertical.VerticalName))),
            );
        });

        return result.filter((stamp) => stamp.Verticals.length > 0);
    }

    private getSelectedVertical = () => {
        if (!this.props.clouds) {
            return null;
        }
        const selectedClouds = this.props.clouds.filter(
            (cloud) => cloud.cloud === this.props.selectedCloud);
        if (selectedClouds.length === 0) {
            return null;
        }
        const selectedVerticals = selectedClouds[0].verticals.filter(
            (vertical) => vertical.vertical === this.props.selectedVertical);
        if (selectedVerticals.length === 0) {
            return null;
        }
        return selectedVerticals[0];
    }

    private getSelectedStamp = () => {
        if (!this.props.stamps) {
            return null;
        }
        const selectedStamps = this.props.stamps.filter(
            (stamp) => stamp.StampName === this.props.selectedStamp);
        if (selectedStamps.length === 0) {
            return null;
        }
        return selectedStamps[0];
    }

    private getSelectedStackVertical = () => {
        const selectedStamp = this.getSelectedStamp();
        if (Is.Null(selectedStamp)) {
            return null;
        }
        const selectedVerticals = selectedStamp.Verticals.filter(
            (vertical) => vertical.VerticalName === this.props.selectedStackVertical);
        if (selectedVerticals.length === 0) {
            return null;
        }
        return selectedVerticals[0];
    }

    private getSelectedVerticalBuild = () => {
        const selectedCloud = this.props.buildStatusDictionary[this.props.selectedCloud];
        if (!selectedCloud) {
            return null;
        }
        return selectedCloud[this.props.selectedVertical];
    }
}

const mapStateToProps = (state) => ({
    isClassicView: state.clouds.isClassicView,
    isApplicationView: state.clouds.isApplicationView,
    selectedStamp: state.clouds.selectedStamp,
    selectedStackVertical: state.clouds.selectedStackVertical,
    stamps: state.clouds.stamps,
    selectedCloud: state.clouds.selectedCloud,
    selectedVertical: state.clouds.selectedVertical,
    clouds: state.clouds.clouds,
    buddyPipelineRuns: state.clouds.buddyPipelineRuns,
    officialPipelineRuns: state.clouds.officialPipelineRuns,
    autoRefresh: state.clouds.autoRefresh,
    searchFilter: state.clouds.searchFilter,
    showProductionOnlyFilter: state.clouds.showProductionOnlyFilter,
    buildStatusDictionary: state.clouds.buildStatusDictionary,
});

const mapDispatchToProps = (dispatch) => ({
    loadVerticals: () => { dispatch(loadVerticals()); },
    loadVerticalBuildingStatus: (cloud: string, vertical: string) => {
        dispatch(loadVerticalBuildingStatus(cloud, vertical));
    },
    loadStamps: () => { dispatch(loadStamps()); },
    loadBuddyPipelineRuns: () => { dispatch(loadBuddyPipelineRuns()); },
    loadOfficialPipelineRuns: () => { dispatch(loadOfficialPipelineRuns()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
