import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import { authProvider } from "../../authProvider";

import {
    verticalBuildingStatusLoaded, verticalBuildingStatusLoadingError, verticalsLoaded, verticalsLoadingError, stampsLoaded, stampsLoadingError,
    buddyPipelineRunsLoadingError, buddyPipelineRunsLoaded, officialPipelineRunsLoadingError, officialPipelineRunsLoaded
} from "./actions";
import { LOAD_VERTICAL_BUILDING_STATUS, LOAD_VERTICALS, LOAD_STAMPS, LOAD_BUDDYPIPELINERUNS, LOAD_OFFICIALPIPELINERUNS } from "./constants";

function* loadVerticals(action) {
    try {
        const token = yield(authProvider.getIdToken());
        const jsonData = yield call(
            axios.get,
            "https://" + window.location.hostname + ":" + (window.location.port || "443") + "/Data/GetAwsVerticalData",
            { headers: { Authorization: "Bearer " + token.idToken.rawIdToken}});
        yield put(verticalsLoaded(jsonData.data));
    } catch (e) {
        if (e.response.status === 403) {
            alert("You are not authorized to view this page.");
        }

        yield put(verticalsLoadingError(e));
    }
}

export function* onLoadVerticals() {
    yield takeEvery(LOAD_VERTICALS, loadVerticals);
}

function* loadVerticalBuildingStatus(action) {
    try {
        const token = yield(authProvider.getIdToken());
        const jsonData = yield call(
            axios.get,
            "https://" + window.location.hostname + "/Data/GetAwsBuildData/" + action.vertical,
            { headers: { Authorization: "Bearer " + token.idToken.rawIdToken}});
        yield put(verticalBuildingStatusLoaded(action.cloud, action.vertical, jsonData.data));
    } catch (e) {
        yield put(verticalBuildingStatusLoadingError(action.cloud, action.vertical, e));
    }
}

export function* onLoadVerticalBuildingStatus() {
    yield takeEvery(LOAD_VERTICAL_BUILDING_STATUS, loadVerticalBuildingStatus);
}

function* loadStamps(action) {
    try {
        const token = yield(authProvider.getIdToken());
        const jsonData = yield call(
            axios.get,
            "https://" + window.location.hostname + ":" + (window.location.port || "443") + "/Data/GetStackStampData",
            { headers: { Authorization: "Bearer " + token.idToken.rawIdToken}});
        yield put(stampsLoaded(jsonData.data));
    } catch (e) {
        yield put(stampsLoadingError(e));
    }
}

export function* onLoadStamps() {
    yield takeEvery(LOAD_STAMPS, loadStamps);
}

function* loadBuddyPipelineRuns(action) {
    try {
        const token = yield(authProvider.getIdToken());
        const jsonData = yield call(
            axios.get,
            "https://" + window.location.hostname + ":" + (window.location.port || "443") + "/Data/GetBuddyPipelineRuns",
            { headers: { Authorization: "Bearer " + token.idToken.rawIdToken}});
        yield put(buddyPipelineRunsLoaded(jsonData.data));
    } catch (e) {
        yield put(buddyPipelineRunsLoadingError(e));
    }
}

export function* onLoadBuddyPipelineRuns() {
    yield takeEvery(LOAD_BUDDYPIPELINERUNS, loadBuddyPipelineRuns);
}

function* loadOfficialPipelineRuns(action) {
    try {
        const token = yield(authProvider.getIdToken());
        const jsonData = yield call(
            axios.get,
            "https://" + window.location.hostname + ":" + (window.location.port || "443") + "/Data/GetOfficialPipelineRuns",
            { headers: { Authorization: "Bearer " + token.idToken.rawIdToken}});
        yield put(officialPipelineRunsLoaded(jsonData.data));
    } catch (e) {
        yield put(officialPipelineRunsLoadingError(e));
    }
}

export function* onLoadOfficialPipelineRuns() {
    yield takeEvery(LOAD_OFFICIALPIPELINERUNS, loadOfficialPipelineRuns);
}