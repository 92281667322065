import { CommandBar, ICommandBarItemProps, ICommandBarProps } from "office-ui-fabric-react/lib/CommandBar";
import { Dialog, IContextualMenuItem } from "office-ui-fabric-react";
import * as React from "react";
import { connect } from "react-redux";
import { Is } from "../../utils/Base";

import { ITheme, withTheme } from "../../utils/localStyledComponents";
import { VerticalToolsJenkins } from "../../utils/VerticalTools";
import { toggleAutoRefresh, toggleDarkMode } from "./actions";

interface IHeaderProps {
    theme: ITheme;
    isClassicView: boolean;
    selectedCloud: string;
    autoRefresh: boolean;
    toggleAutoRefresh: () => void;
    toggleDarkMode: () => void;
}

interface IState {
    addStackVerticalsDialogIsVisible: boolean;
}

export class Header extends React.PureComponent<IHeaderProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            addStackVerticalsDialogIsVisible: false,
        };
    }
    public render(): JSX.Element {
        return (
            <>
                <header>
                    <CommandBar {...this.getCommandBarProps()} />
                </header>
                <Dialog
                    dialogContentProps={{ title: "Add Stack Verticals" }}
                    hidden={!this.state.addStackVerticalsDialogIsVisible}
                    onDismiss={this.hideAddStackVerticals}
                >
                    <ol>
                        <li>Follow the steps outlined in <a href="https://microsoft.visualstudio.com/PlayFab/_wiki/wikis/PlayFab/144221/Creating-a-new-Vertical-Application" target="_blank">this document</a></li>
                        <li><a href="https://microsoft.visualstudio.com/DefaultCollection/PlayFab/_git/Stack.AzureDeployment/pullrequest/10160100" target="_blank">Sample PR</a></li> 
                    </ol>
                </Dialog>
            </>            
        );
    }

    private getCommandBarProps = (): ICommandBarProps => {
        const websitelinks: IContextualMenuItem[] = ["Dev", "DevP", "Prod", "ProdP"].map((e) => {return { key: e, text: `${e} Website`, href: `https://${e.toLowerCase()}-infradashboard.azurewebsites.net/`}})
        const environment: ICommandBarItemProps = 
            {
              key: 'Environment',
              text: 'due to PME migration and the security initiative, new pages dedicated to Corp/PME are created. Please use the following links to access the new pages.',
              subMenuProps: {
                items: websitelinks,
                styles: {
                    root: {
                        color: this.props.theme.color.normalFont,
                        backgroundColor: this.props.theme.color.cardBackground,
                    },
                    container: {
                        color: this.props.theme.color.normalFont,
                        backgroundColor: this.props.theme.color.cardBackground,
                    }
                }
              },
              buttonStyles: {
                root: {
                    color: this.props.theme.color.secondaryLinkFont,
                    backgroundColor: this.props.theme.color.headerBackground,
                    padding: "0",
                },
                rootHovered: {
                    color: this.props.theme.color.secondaryLinkFont,
                    backgroundColor: this.props.theme.color.headerBackground,
                },
                rootPressed: {
                    color: this.props.theme.color.secondaryLinkFont,
                    backgroundColor: this.props.theme.color.headerBackground,
                },
                rootExpanded: {
                    color: this.props.theme.color.secondaryLinkFont,
                    backgroundColor: this.props.theme.color.headerBackground,
                },
                rootFocused: {
                    color: this.props.theme.color.secondaryLinkFont,
                    backgroundColor: this.props.theme.color.headerBackground,
                },
                rootExpandedHovered: {
                    color: this.props.theme.color.secondaryLinkFont,
                    backgroundColor: this.props.theme.color.headerBackground,
                },
                icon: {
                    color: this.props.theme.color.secondaryLinkFont,
                },
                menuIcon: {
                    color: "white",
                    fontSize: "12px",
                },
            },
            }
        return {
            styles: {
                root: {
                    borderBottom: `1px solid ${this.props.theme.color.border}`,
                    backgroundColor: this.props.theme.color.headerBackground,
                    paddingLeft: "0",
                },
            },
            items: [
                {
                    key: "title",
                    text: "PlayFab Verticals",
                    buttonStyles: {
                        root: {
                            color: this.props.theme.color.headerFont,
                            backgroundColor: this.props.theme.color.headerBackground,
                            width: this.props.theme.size.primaryNavWidth,
                            padding: "0",
                            right: "-2px",
                        },
                        rootHovered: {
                            color: this.props.theme.color.headerFont,
                            backgroundColor: this.props.theme.color.headerBackground,
                        },
                        rootPressed: {
                            color: this.props.theme.color.headerFont,
                            backgroundColor: this.props.theme.color.headerBackground,
                        },
                        flexContainer: {
                            borderRight: `1px solid ${this.props.theme.color.secondaryBorder}`,
                        },
                        icon: {
                            color: this.props.theme.color.playfabOrange,
                        },
                    },
                    iconProps: {
                        iconName: "ServerEnviroment",
                        styles: {
                            root: {
                                paddingLeft: "1.5rem",
                            },
                        },
                    },
                },
                environment,
            ],
            farItems: [
                this.createCommandBarItem("changetheme", "Change theme", undefined,  "Color", this.props.toggleDarkMode, undefined),
                this.createCommandBarItem("add", "Add vertical", undefined,  "Add", this.addVerticalOnClick(), this.addVerticalHref()),
                this.createCommandBarItem("autorefresh", "Auto refresh", undefined,  this.props.autoRefresh ? "Refresh" : "Pause", this.props.toggleAutoRefresh, undefined),
                this.createCommandBarItem("jenkins-verticals-jobs", "Verticals jobs", "Jenkins", undefined, undefined, "https://pf-jenkins.playfab.com/view/Verticals/"),
                this.createCommandBarItem("Doc", "Doc", "Doc", undefined, undefined, "https://microsoft.visualstudio.com/GDX/_wiki/wikis/GDX/134194/Verticals-Dashboard"),
            ],
        };
    }

    private createCommandBarItem = (key: string, title: string, text?: string, iconName?: string, onClick?: () => void, href?: string): any => {
        let action = {};
        if (!Is.Null(onClick)) {
            action = { onClick };
        } else if (!Is.Null(href)) {
            action = { href, target: "_blank" };
        }
        const icon = iconName !== null ? { iconProps: { iconName } } : {};
        return {
            ...action,
            ...icon,
            text,
            key,
            title,
            buttonStyles: {
                root: {
                    color: this.props.theme.color.secondaryLinkFont,
                    backgroundColor: this.props.theme.color.headerBackground,
                    padding: "0",
                },
                rootHovered: {
                    color: this.props.theme.color.secondaryLinkFont,
                    backgroundColor: this.props.theme.color.headerBackground,
                },
                rootPressed: {
                    color: this.props.theme.color.secondaryLinkFont,
                    backgroundColor: this.props.theme.color.headerBackground,
                },
                icon: {
                    color: this.props.theme.color.secondaryLinkFont,
                },
            },
        };
    }

    private addVerticalOnClick = () => {
        return this.props.isClassicView ? undefined : () => this.showAddStackVerticals();
    }

    private addVerticalHref = () => {
        return this.props.isClassicView ? VerticalToolsJenkins.JobStartFormUrl(
            "add-vertical",
            [["Cloud", this.props.selectedCloud]],
        ) : undefined;
    }

    private hideAddStackVerticals = () => {
        this.setState({
            ...this.state,
            addStackVerticalsDialogIsVisible: false,
        });
    };

    private showAddStackVerticals = () => {
        this.setState({
            ...this.state,
            addStackVerticalsDialogIsVisible: true,
        });
    };
}

const mapStateToProps = (state) => ({
    selectedCloud: state.clouds.selectedCloud,
    autoRefresh: state.clouds.autoRefresh,
    isClassicView: state.clouds.isClassicView,
});

const mapDispatchToProps = (dispatch) => ({
    toggleAutoRefresh: () => { dispatch(toggleAutoRefresh()); },
    toggleDarkMode: () => { dispatch(toggleDarkMode()); },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Header));
