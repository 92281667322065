import * as React from "react";
import { connect } from "react-redux";

import { darkTheme, defaultTheme, ThemeProvider } from "../..//utils/localStyledComponents";

interface IHeaderProps {
    darkMode: boolean;
}

export class AppThemeProvider extends React.PureComponent<IHeaderProps> {
    public render(): JSX.Element {
        return (
            <ThemeProvider theme={this.props.darkMode ? darkTheme : defaultTheme}>
                <React.Fragment>{this.props.children}</React.Fragment>
            </ThemeProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    darkMode: state.clouds.darkMode,
});

const mapDispatchToProps = (_) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AppThemeProvider) as unknown as React.ComponentClass<{}>;
