import { Icon, IconButton } from "office-ui-fabric-react";
import * as React from "react";

import { IReactEnumable, Is } from "../../utils/Base";
import { IClusterProps, StackApplicationCluster } from "./StackApplicationCluster";
import { CardWrapper } from "../../utils/localStyledComponents";
import { VerticalToolsUrl } from "../../utils/VerticalTools";
import { IPipelineRun } from "../../containers/HomePage/reducer";

export interface IStackApplication extends IReactEnumable {
    ApplicationName: string;
    VerticalName: string;
    StampName: string;
    Clusters: IClusterProps[];
    buddyPipelineRuns: IPipelineRun[];
    officialPipelineRuns: IPipelineRun[];
}

export class StackApplication extends React.PureComponent<IStackApplication> {
    public render() {
        const cardClassName = `ui stackcard stamp-application blue`;
        const headerLink = VerticalToolsUrl.applicationRoute(this.props.VerticalName, this.props.ApplicationName + "_stack", VerticalToolsUrl.getCloudName(this.props.StampName));
        const stackApplicationName = this.props.ApplicationName + "_stack";
        const stackCloudName = VerticalToolsUrl.getCloudName(this.props.StampName)
        const header = Is.Null(headerLink)
            ? this.props.ApplicationName
            : (<a href={headerLink} target="_blank" rel="noopener noreferrer">{this.props.ApplicationName}</a>);

        let clusterElements: JSX.Element = (<div />);
        if (!Is.Null(this.props.Clusters)) {
            clusterElements = (
                <div>
                    {this.props.Clusters.map((c: IClusterProps) => {
                        return (
                            <StackApplicationCluster
                                key={c.ClusterName}
                                ApplicationName={c.ApplicationName}
                                DeploymentName={c.DeploymentName}
                                Namespace={c.Namespace}
                                StampName={c.StampName}
                                StampSubscription={c.StampSubscription}
                                ClusterName={c.ClusterName}
                                ReplicaSets={c.ReplicaSets}
                                EndpointWeight={c.EndpointWeight}
                                buddyPipelineRuns={this.props.buddyPipelineRuns}
                                officialPipelineRuns={this.props.officialPipelineRuns}
                            />
                        );
                    })}
                </div>
            );
        }

        return (
            <CardWrapper>
                <div className={cardClassName}>
                    <div className="application-helpers">
                        {VerticalToolsUrl.mdmLogsExist(stackApplicationName)
                            ? (
                                <IconButton
                                    title="Geneva Logs"
                                    iconProps={{ iconName: "TextDocument" }}
                                    target="_blank"
                                    href={VerticalToolsUrl.mdmLogsForApplicationCommit(this.props.VerticalName, stackApplicationName, "", stackCloudName)}
                                />
                            )
                            : null}
                        {VerticalToolsUrl.kustoLogsExist(stackApplicationName)
                            ? (
                                <IconButton
                                    title="Kusto Logs"
                                    iconProps={{ iconName: "QueryList" }}
                                    target="_blank"
                                    href={VerticalToolsUrl.kustoLogsForApplicationCommit(this.props.VerticalName, stackApplicationName, "", stackCloudName)}
                                />
                            )
                            : null}
                        {VerticalToolsUrl.mdmDashExist(stackApplicationName)
                            ? (
                                <IconButton
                                    title="Geneva Dashboard"
                                    iconProps={{ iconName: "LineChart" }}
                                    target="_blank"
                                    href={VerticalToolsUrl.mdmDashForApplicationCommit(this.props.VerticalName, stackApplicationName, "", stackCloudName)}
                                />
                            )
                            : null}
                    </div>
                    <div className="content">
                        <div className="header">
                            {header}
                        </div>
                        {clusterElements}
                    </div>
                </div>
            </CardWrapper>
        );
    }
}
