import { Dialog, Icon, IconButton } from "office-ui-fabric-react";
import * as React from "react";

import { IReactEnumable, Is } from "../../utils/Base";
import { CardWrapper } from "../../utils/localStyledComponents";
import { VerticalToolsUrl } from "../../utils/VerticalTools";
import { IPipelineRun } from "../../containers/HomePage/reducer";
import { IApplicationProps, StackClusterApplication } from "./StackClusterApplication";

interface IState {
    isDialogOpen: boolean;
}

export interface IStackCluster extends IReactEnumable {
    ClusterName: string;
    StampName: string;
    VerticalName: string;
    StampSubscription: string;
    EndpointWeight: number;
    ApplicationNodeCount: number;
    DefaultNodeCount: number;
    Applications: IApplicationProps[];
    buddyPipelineRuns: IPipelineRun[];
    officialPipelineRuns: IPipelineRun[];
}

export class StackCluster extends React.PureComponent<IStackCluster, IState> {
    constructor(props) {
        super(props);
        this.state = {
            isDialogOpen: false,
        };
    }

    public render() {
        const cardClassName = `ui stackclustercard stamp-cluster blue`;
        const trafficManagerRoute = VerticalToolsUrl.trafficManagerRoute(
            this.props.StampSubscription, this.props.StampName, this.props.ClusterName);
        const managedClusterRoute = VerticalToolsUrl.managedClusterRoute(
            this.props.StampSubscription, this.props.StampName, this.props.ClusterName);
        const stackCloudName = VerticalToolsUrl.getCloudName(this.props.StampName)
        const setClusterSubscriptionCommand = `az account set --subscription ${this.props.StampSubscription}`
        const downloadClusterCredentialsCommand = `az aks get-credentials --resource-group ${this.props.StampName}-01-shared --name ${this.props.ClusterName}`

        let applicationElements: JSX.Element = (<div />);
        if (!Is.Null(this.props.Applications)) {
            applicationElements = (
                <div className="ui stackclustercard">
                    {this.props.Applications.map((a: IApplicationProps) => {
                        return (
                            <StackClusterApplication
                                key={a.ApplicationName}
                                ApplicationName={a.ApplicationName}
                                StampName={this.props.StampName}
                                VerticalName={this.props.VerticalName}
                                StampSubscription={this.props.StampSubscription}
                                ClusterName={this.props.ClusterName}
                                DeploymentCreationTime={a.DeploymentCreationTime}
                                ReplicaSets={a.ReplicaSets}
                                EndpointWeight={this.props.EndpointWeight}
                                buddyPipelineRuns={this.props.buddyPipelineRuns}
                                officialPipelineRuns={this.props.officialPipelineRuns}
                            />
                        );
                    })}
                </div>
            );
        }

        return (
            <CardWrapper>
                <div className={cardClassName}>
                    <div className="cluster-helpers">
                        <IconButton
                            title="Connect to Cluster"
                            iconProps={{ iconName: "PlugConnected" }}
                            onClick={() => this.showCommands()}
                        />
                        <IconButton
                            title="Geneva Dashboard"
                            iconProps={{ iconName: "LineChart" }}
                            target="_blank"
                            href={VerticalToolsUrl.mdmDashForApplicationCluster("", "", stackCloudName, this.props.ClusterName)}
                        />
                    </div>
                    <div className="content">
                        <div className="header">
                            {Is.Null(managedClusterRoute)
                                ? this.props.ClusterName
                                : (<a href={managedClusterRoute} target="_blank" rel="noopener noreferrer">{this.props.ClusterName}</a>)}
                        </div>
                        <div className="ui segment stamp-cluster">
                            <Dialog
                                dialogContentProps={{ title: "Connect to " + this.props.ClusterName }}
                                hidden={!this.state.isDialogOpen}
                                onDismiss={this.hideCommands}
                            >
                                <h4>Set the cluster subscription</h4>
                                <div>{setClusterSubscriptionCommand}</div>
                                <div>
                                    <IconButton
                                        title="Copy to Clipboard"
                                        iconProps={{ iconName: "Copy" }}
                                        onClick={() => navigator.clipboard.writeText(setClusterSubscriptionCommand)}
                                    />
                                </div>
                                <h4>Download cluster credentials</h4>
                                <div>{downloadClusterCredentialsCommand}</div>
                                <div>
                                    <IconButton
                                        title="Copy to Clipboard"
                                        iconProps={{ iconName: "Copy" }}
                                        onClick={() => navigator.clipboard.writeText(downloadClusterCredentialsCommand)}
                                    />
                                </div>
                            </Dialog>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Application Nodes</th>
                                        <td>{this.props.ApplicationNodeCount}</td>
                                    </tr>
                                    <tr>
                                        <th>Default Nodes</th>
                                        <td>{this.props.DefaultNodeCount}</td>
                                    </tr>
                                    <tr>
                                        <th>Traffic</th>
                                        <td>
                                            <a href={trafficManagerRoute} target="_blank" rel="noopener noreferrer" className="value">{Math.round(this.props.EndpointWeight * 100)}%</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {applicationElements}
                        </div>
                    </div>
                </div>
            </CardWrapper>
        );
    }


    private showCommands = () => {
        this.setState({
            ...this.state,
            isDialogOpen: true,
        });
    }

    private hideCommands = () => {
        this.setState({
            ...this.state,
            isDialogOpen: false,
        });
    }
}
