import * as React from "react";

import { AppContainer } from "../utils/localStyledComponents";
import { IPipelineRun, IStackVertical, IStamp } from "../containers/HomePage/reducer";
import { StackApplication } from "./Stamp/StackApplication";
import StackHeader from "./StackHeader";
import { IClusterProps } from "./Stamp/StackApplicationCluster";
import { IReplicaSetProps } from "./Stamp/StackApplicationReplicaSet";
import { VerticalToolsSort } from "../utils/VerticalTools";
import { connect } from "react-redux";
import { StackCluster } from "./Stamp/StackCluster";
import { IApplicationProps } from "./Stamp/StackClusterApplication";

interface IStateProps {
    isApplicationView: boolean;
}

interface IStackContentProps {
    vertical: IStackVertical;
    stamp: IStamp;
    buddyPipelineRuns: IPipelineRun[];
    officialPipelineRuns: IPipelineRun[];
}

type Props = IStackContentProps & IStateProps;

export class StackContent extends React.PureComponent<Props> {
    public render(): JSX.Element {
        return (
            <div>
                <StackHeader vertical={this.props.vertical} stamp={this.props.stamp} />
                {this.props.isApplicationView ? this.getApplicationView() : this.getClusterView()}
            </div>
        );
    }

    private getReplicaSetProps(replicasets, app, cluster): IReplicaSetProps[] {
        return replicasets.sort(VerticalToolsSort.sortReplicaSet).map((rs) => {
            return {
                ...rs,
                ApplicationName: app.ApplicationName,
                Namespace: app.Namespace,
                StampName: this.props.stamp.StampName,
                StampSubscription: this.props.stamp.StampSubscription,
                ClusterName: cluster.ClusterName,
                EndpointWeight: cluster.EndpointWeight,
                buddyPipelineRuns: this.props.buddyPipelineRuns,
                officialPipelineRuns: this.props.officialPipelineRuns,
            }
        });
    }

    private getClusterView(): JSX.Element {
        var clusterElements = this.props.vertical.Clusters.map((cluster) => {
            var apps: IApplicationProps[] =
                cluster.Applications.sort((a, b) => VerticalToolsSort.sortApps(a.ApplicationName, b.ApplicationName)).map((app) => {
                    return {
                        ApplicationName: app.ApplicationName,
                        DeploymentCreationTime: app.DeploymentCreationTime,
                        StampName: this.props.stamp.StampName,
                        VerticalName: this.props.vertical.VerticalName,
                        StampSubscription: this.props.stamp.StampSubscription,
                        ClusterName: cluster.ClusterName,
                        ReplicaSets: this.getReplicaSetProps(app.ReplicaSets, app, cluster),
                        EndpointWeight: cluster.EndpointWeight,
                        buddyPipelineRuns: this.props.buddyPipelineRuns,
                        officialPipelineRuns: this.props.officialPipelineRuns,
                    }
                });
            return (
                <StackCluster
                    key={cluster.ClusterName}
                    ClusterName={cluster.ClusterName}
                    StampSubscription={this.props.stamp.StampSubscription}
                    StampName={this.props.stamp.StampName}
                    VerticalName={this.props.vertical.VerticalName}
                    EndpointWeight={cluster.EndpointWeight}
                    ApplicationNodeCount={cluster.ApplicationNodeCount}
                    DefaultNodeCount={cluster.DefaultNodeCount}
                    Applications={apps}
                    buddyPipelineRuns={this.props.buddyPipelineRuns}
                    officialPipelineRuns={this.props.officialPipelineRuns}
                />
            );
        });
        return <div>{clusterElements}</div>
    }

    private getApplicationMetadata(): { [applicationName: string]: IClusterProps[] } {
        var applicationMetadata = {}
        this.props.vertical.Clusters.map((cluster) => {
            cluster.Applications.map((app) => {
                var rs: IReplicaSetProps[] = this.getReplicaSetProps(app.ReplicaSets, app, cluster);
                var c: IClusterProps = {
                    DeploymentName: app.DeploymentName,
                    EndpointWeight: cluster.EndpointWeight,
                    ClusterName: cluster.ClusterName,
                    ApplicationName: app.ApplicationName,
                    Namespace: app.Namespace,
                    StampName: this.props.stamp.StampName,
                    StampSubscription: this.props.stamp.StampSubscription,
                    ReplicaSets: rs,
                    buddyPipelineRuns: this.props.buddyPipelineRuns,
                    officialPipelineRuns: this.props.officialPipelineRuns,
                }
                if (applicationMetadata[app.ApplicationName] === undefined) {
                    applicationMetadata[app.ApplicationName] = [];
                }
                applicationMetadata[app.ApplicationName].push(c)
            });
        });
        applicationMetadata = Object.keys(applicationMetadata).sort(VerticalToolsSort.sortApps).reduce(
            (obj, key) => {
                obj[key] = applicationMetadata[key];
                return obj;
            },
            {}
        );
        return applicationMetadata;
    }

    private getApplicationView(): JSX.Element {
        var applicationMetadata = this.getApplicationMetadata();
        var applicationElements = Object.keys(applicationMetadata).map((appName) => {
            return (
                <StackApplication
                    key={appName}
                    ApplicationName={appName}
                    Clusters={applicationMetadata[appName]}
                    VerticalName={this.props.vertical.VerticalName}
                    StampName={this.props.stamp.StampName}
                    buddyPipelineRuns={this.props.buddyPipelineRuns}
                    officialPipelineRuns={this.props.officialPipelineRuns}
                />
            );
        });
        return <AppContainer>{applicationElements}</AppContainer>
    }
}

const mapStateToProps = (state): IStateProps => ({
    isApplicationView: state.clouds.isApplicationView,
});

export default connect(mapStateToProps)(StackContent);