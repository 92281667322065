import { Icon } from "office-ui-fabric-react/lib/Icon";
import * as React from "react";

import styled from "../utils/localStyledComponents";

interface IStatusCircle {
    success?: boolean;
    isBig?: boolean;
}

interface IStyled {
    className?: string;
}

class StatusIcon extends React.PureComponent<IStatusCircle & IStyled, {}> {
    public render() {
        return (<Icon iconName={"StatusCircleInner"} className={this.props.className}/>);
    }
}

export const StatusCircle = styled(StatusIcon)<IStatusCircle>`
    color: ${(props) => props.success ? props.theme.color.success : props.theme.color.failed}
    font-size:${(props) => props.isBig ? "45px" : "16px"};
    ${(props) => props.isBig ? "line-height: 45px;" : ""}
`;
