import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import * as React from "react";
import AzureAD from "react-aad-msal";
import { Provider } from "react-redux";
import { authProvider } from "./authProvider";

import HomePage from "./containers/HomePage/HomePage";
import ThemeProvider from "./containers/ThemeProvider";
import GlobalStyles from "./GlobalStyles";
import store from "./store";
import { Is } from "./utils/Base";

initializeIcons();

export class App extends React.PureComponent<{}, {}> {

    public render(): JSX.Element {
        return (
            <AzureAD provider={authProvider} forceLogin={true}>
                {
                    ({_, accountInfo}) => {
                        return (
                            <Provider store={store}>
                                <ThemeProvider>
                                    <React.Fragment>
                                        <GlobalStyles />
                                        {(Is.Null(accountInfo))
                                            ? null
                                            : <HomePage />
                                        }
                                    </React.Fragment>
                                </ThemeProvider>
                            </Provider>
                        );
                    }
                }
            </AzureAD>
        );
    }
}
