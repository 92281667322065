import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import * as React from "react";

import { EVerticalStatus, Status } from "../utils/VerticalTools";
import { StatusCircle } from "./Icon";

export interface IVerticalStatusProps {
    status: string;
    isBig?: boolean;
}

export default class VerticalStatus extends React.PureComponent<IVerticalStatusProps> {
    public render() {
        const status = Status.Normalize(this.props.status);
        if (status === EVerticalStatus.Ready) {
            return (<StatusCircle success isBig={this.props.isBig} />);
        } else if (status === EVerticalStatus.Failed) {
            return (<StatusCircle isBig={this.props.isBig} />);
        } else {
            return (
                <Spinner
                    size={this.props.isBig ? SpinnerSize.large : SpinnerSize.small}
                    styles={{root: {display: "inline-block"}}}
                />
            );
        }
    }
}
