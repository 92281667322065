import { LoginType, MsalAuthProvider } from "react-aad-msal";

export const authProvider = new MsalAuthProvider({
    auth: {
        authority: "https://login.microsoftonline.com/microsoft.com",
        clientId: "78b46bdb-0acd-494e-b876-f43b548b6a56",
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
    },
},
{
    scopes: ["https://graph.microsoft.com/.default"],
},
{
    loginType: LoginType.Redirect,
});
