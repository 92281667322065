import { Is } from "../../utils/Base";

export const createCommandBarItem = (key: string, title?: string, text?: string, iconName?: string, onClick?: () => void, href?: string, color?: any): any => {
    let action = {};
    if (!Is.Null(onClick)) {
        action = { onClick };
    } else if (!Is.Null(href)) {
        action = { href, target: "_blank" };
    }
    const icon = iconName !== null ? { iconProps: { iconName } } : {};
    return {
        ...action,
        ...icon,
        key,
        title,
        text,
        buttonStyles: {
            root: {
                backgroundColor: color.background,
                padding: "0",
            },
            rootHovered: {
                backgroundColor: color.background,
            },
            rootPressed: {
                backgroundColor: color.background,
            },
            icon: {
                color: color.linkFont,
            },
            label: {
                color: color.normalFont,
            },
        },
    };
}